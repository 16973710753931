<template>
  <button
    @click="$emit('delete')"
    type="button"
    class="btn btn-success buttonRound mx-1"
    tooltip="Editar"
    flow="down"
  >
    <i class="ti ti-edit"></i>
  </button>
</template>

<script>
export default {
  name: 'ButtonOnSubmitEdit',

  methods: {
    delete() {
      this.$emit('edit');
    }
  }
};
</script>
