import AdminComponent from "@/components/dashboard/AdminComponent";

import ReportAccountBank from "@/views/report/account-bank.vue";
import ReportBudget from "@/views/report/budget.vue";
import ReportClient from "@/views/report/client.vue";
import ReportProduct from "@/views/report/product.vue";
import ReportProductEntranceAndExit from "@/views/report/product-entrance-and-exit.vue";
import ReportProductPhoto from "@/views/report/product-photo.vue";
import ReportBoxTotalEntrance from "@/views/report/box-total-entrance.vue";
import ReportBoxTotalExit from "@/views/report/box-total-exit.vue";
import ReportBoxTotalMoviment from "@/views/report/box-total-moviment.vue";
import ReportClientEmail from "@/views/report/client-email.vue";
import ReportClientPhone from "@/views/report/client-phone.vue";
import ReportFinancialEntrancePreview from "@/views/report/financial-entrance-preview.vue"
import ReportFinancialEntranceeffective from "@/views/report/financial-entrance-effective.vue"
import ReportFinancialExitPreview from "@/views/report/financial-exit-preview.vue"
import ReportFinancialExiteffective from "@/views/report/financial-exit-effective.vue"
import ReportSale from "@/views/report/sale.vue"

export  default {

        path: '/report',
        component: AdminComponent,
        meta: {auth: true},
        children: [
            {path: "account-bank", name: "report-account-bank", component: ReportAccountBank},
            {path: "budget", name: "report-budget", component: ReportBudget},
            {path: "box-total-entrance", name: "box-total-entrance", component: ReportBoxTotalEntrance},
            {path: "box-total-exit", name: "box-total-exit", component: ReportBoxTotalExit},
            {path: "box-total-movement", name: "box-total-movement", component: ReportBoxTotalMoviment},
            {path: "client", name: "report-client", component: ReportClient},
            {path: "client-email", name: "report-client-email", component: ReportClientEmail},
            {path: "client-phone", name: "report-client-phone", component: ReportClientPhone},
            {path: "financial-entrance-preview", name: "report-financial-entrance-preview", component: ReportFinancialEntrancePreview},
            {path: "financial-entrance-effective", name: "report-financial-entrance-effective", component: ReportFinancialEntranceeffective},
            {path: "financial-exit-preview", name: "report-financial-exit-preview", component: ReportFinancialExitPreview},
            {path: "financial-exit-effective", name: "report-financial-exit-effective", component: ReportFinancialExiteffective},
            {path: "product", name: "report-product", component: ReportProduct},
            {path: "product-photo", name: "report-product-photo", component: ReportProductPhoto},
            {path: "product-entrance-and-exit", name: "report-product-entrance-and-exit", component: ReportProductEntranceAndExit},
            {path: "sale", name: "report-sale", component: ReportSale},


        ]

}








