<template>
    <div class="painelcomponent">
        <div class="iconPainel">
            <i :class="'ti ' + itens.icon"></i>
        </div>

        <h2>{{ itens.title }}</h2>

        <div class="painelContent">
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "PainelComponent",

    props: {
        itens: {
            "required": true,
            "type": Object,
            "default": []
        },
    },
}
</script>
  
<style scoped>
.painelcomponent {
    position: relative;
    background-color: white;
    padding: 2%;
    margin: 5% 2% 5% 2%;
    -webkit-box-shadow: 10px 10px 23px 0px rgba(204, 204, 204, 1);
    -moz-box-shadow: 10px 10px 23px 0px rgba(204, 204, 204, 1);
    box-shadow: 10px 10px 23px 0px rgba(204, 204, 204, 1);
}

.painelcomponent .iconPainel {
    position: absolute;
    background-color: #36c1bd;
    padding: 20px;
    top: -40px;
    height: 80px;
    width: 80px;
    border-radius: 10px;

}

.painelcomponent h2 {
    width: fit-content;
    font-size: 20px;
    position: relative;
    left: 100px;
    top: -19px;
    color: #000;
    font-weight: 50;
}

.painelcomponent .iconPainel i {
    color: white;
    font-size: 35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.painelcomponent .painelContent {
    clear: both;
}

@media(max-width:991px) {
    .painelcomponent .iconPainel {
        top: -20px;
        padding: 10px 15px;
        height: 50px;
        width: 50px;
    }

    .painelcomponent h2 {
        top: 0;
        left: 60px;
        font-size: 16px;
    }

    .painelcomponent .iconPainel i {
        font-size: 20px;
    }

}

@media(max-width: 776px) {
    .painelcomponent {
        margin-top: 10%;
    }
}
</style>