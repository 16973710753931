<template>
  <painel-component :itens="itensOne">
    <form v-if="showView">
      <row-component>
        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Marca:</label>
          <Multiselect v-model="formData.brand_id" :options="selectBrandList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Categoria:</label>
          <Multiselect v-model="formData.category_id" :options="selectCategoryList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>


      </row-component>

      <row-component>
        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" :items="buttonSubmit"/>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import RowColComponent from "@/components/row/RowColComponent.vue";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";

export default {
  name: "ReportProduct",
  components: {
    ButtonSubmit,
    Multiselect,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  created() {
    this.$store.dispatch("updateNamePage", "Relatório Produto com foto")
    this.$store.dispatch('loadBrandMultiSelect')
    this.$store.dispatch('loadCategoryMultiSelect')
    this.checkACL()
  },

  data() {
    return {
      itensOne: {
        title: "Gerar",
        icon: "ti-file-download"
      },

      buttonSubmit: {
        icon: 'ti-download',
        typeButton: "button"
      },

      formData: {
        status: 1,
        brand_id: "",
        category_id: "",
        excel_pdf: 2
      },

      showView: false
    }
  },

  methods: {
    submit() {
      const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://system.benepiscinas.com' : 'http://bene.d7mmarketing.com.br';
      const RESOURCE = "/private/report/product-photo/g5EGWP4oxPW1oYkXr0aE"
      const params = new URLSearchParams({
        status: this.formData.status,
        brand_id: this.formData.brand_id,
        category_id: this.formData.category_id,
        excel_pdf: this.formData.excel_pdf
      });

      const keysToRemove = ['status', 'brand_id', 'category_id', 'excel_pdf'];
      keysToRemove.forEach(key => {
        if (this.formData[key] === null || this.formData[key] === "") {
          params.delete(key);
        }
      });

      const FULL_URL = `${URL_BASE}${RESOURCE}?${params.toString()}`
      window.open(FULL_URL)
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 22)
          .then((r) => {
            if (r.data.indexOf('report') !== -1) {
              this.showView = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },
  },

  computed: {
    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList
    },

    selectCategoryList() {
      return this.$store.state.var.selectCategoryList
    },

    selectTypeReport() {
      return this.$store.state.var.selectTypeReport
    },
  }
}
</script>