import actions from '@/store/modules/service-order/actions';
import getters from '@/store/modules/service-order/getters';
import mutations from '@/store/modules/service-order/mutations';
import state from '@/store/modules/service-order/state';

export default {
  actions,
  getters,
  mutations,
  state
};
