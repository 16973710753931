
export default {
    icon: "ti  ti-cash",
    name: "PDV",
    subLinks: [
      {
            name: "Caixa Histórico",
            route: "/pdv-box-historic/search",
            pagePermissions: [
                "pdv-box-historic",
                "pdv-box-historic-search",
                "pdv-box-historic-show",
            ],
        },
        {
            name: "Pedidos Histórico",
            route: "/pdv-sales-historic/search",
            pagePermissions: [
                "pdv-sales-historic",
                "pdv-sales-historic-search",
                "pdv-sales-historic-show",
            ],
        },

        {
          name: "Dashboard Caixa",
            route: "/pdv-box-dashboard/search",
            pagePermissions: [
                "pdv-box-dashboard",
                "pdv-box-dashboard-search",
                "pdv-box-dashboard-show",
            ],
        },




    ],

    pageLinksNavCheck: [
        "pdv-box-historic",
        "pdv-box-historic-search",
        "pdv-box-historic-show",
        "pdv-sales-historic",
        "pdv-sales-historic-search",
        "pdv-sales-historic-show",
        "pdv-box-dashboard",
        "pdv-box-dashboard-search",
        "pdv-box-dashboard-show",
    ]
}