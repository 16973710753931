<template>

  <div class="modal-search-client" v-if="modalClient">

    <div class="btn-close" @click="modalClient = !modalClient"></div>
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
      >
        <input v-model="client.type" type="radio" name="docs" id="tp-one" value="1" />
        <label for="tp-one">CPF:</label>

        <input
          v-model="client.type"
          type="radio"
          name="docs"
          id="tp-two"
          value="2"
          checked
        />
        <label for="tp-two">CNPJ:</label>
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
      >
        <label>Nome:</label>
        <input-component v-model="client.name" type-input="text" />
      </row-col-component>

      <row-col-component
        v-if="client.type === '1'"
        :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>CPF:</label>
        <input-component
          v-mask="'###.###.###-##'"
          v-model="client.cpf"
          type-input="text"
        />
      </row-col-component>

      <row-col-component
        v-if="client.type === '2'"
        :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>CNPJ:</label>
        <input-component
          v-mask="'##.###.###/####-##'"
          v-model="client.cnpj"
          type-input="text"
        />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'"
      >
        <button-submit @click="submitSearch" :items="buttonSearch" />
      </row-col-component>
    </row-component>

    <div class="mt-4">
      <row-component>
        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          v-for="(data, index) in object.data"
          :key="index"
        >
          <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
            <div class="h-100 avatar__image__div">
              <img
                class="avatar__image w-100"
                v-if="data.photo"
                :src="data.photo"
                alt=""
              />
              <img
                class="avatar__image w-100"
                v-else
                src="@/assets/images/avatar.png"
                alt=""
              />
            </div>
            <div class="ms-3 w-75">
              <p class="mb-1 text-truncate">{{ data.name }}</p>
              <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
            </div>
            <div class="position-absolute end-0 me-1 me-lg-3">
              <button-submit @click="selectClient(data)" :items="buttonAdd" />
            </div>
          </div>
        </row-col-component>
      </row-component>

    </div>
  </div>

  <div class="modal-search-phone" v-if="modalPhone">
    <div class="btn-close" @click="modalPhone = !modalPhone"></div>
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
      >
        <label>Telefone:</label>
        <Multiselect
          v-model="formData.phone"
          :options="selectClientPhoneList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchClientPhone" :items="buttonConfirm" />
  </div>

  <div class="modals position-fixed top-0 start-0 d-grid" v-if="modal_add_date_hour">
    <div class="send-sector" v-if="modal_add_date_hour">
      <div role="button" class="btn-close" @click="modal_add_date_hour = false"></div>
      <div class="mt-3">
        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          >
            <label>Tipo:</label>
            <Multiselect
              v-model="formHour.type"
              :options="selectTypeDate"
              :searchable="true"
              :noOptionsText="noOptionsText"
              :noResultsText="noResultsText"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          >
            <label>Data do serviço:</label>
            <input-component :type-input="'date'" v-model="formHour.date" />
          </row-col-component>
          <row-col-component
            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          >
            <label>Hora do serviço:</label>

            <input-component
              v-mask="'##:##'"
              :type-input="'text'"
              v-model="formHour.hour"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <button-submit
              @submit="submitHour"
              :disabled="sendForm"
              class="w-100"
              :items="btnSubmitHour"
            />
          </row-col-component>
        </row-component>
      </div>
    </div>
  </div>

  <div class="modals position-fixed top-0 start-0 d-grid" v-if="edit_date_hour">
    <div class="send-sector" v-if="edit_date_hour">
      <div
        role="button"
        class="btn-close"
        @click="(edit_date_hour = false), resetForm()"
      ></div>
      <div class="mt-3">
        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          >
            <label>Tipo:</label>
            <Multiselect
              v-model="formHour.type"
              :options="selectTypeDate"
              :searchable="true"
              :noOptionsText="noOptionsText"
              :noResultsText="noResultsText"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          >
            <label>Data do serviço:</label>
            <input-component :type-input="'date'" v-model="formHour.date" />
          </row-col-component>
          <row-col-component
            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          >
            <label>Hora do serviço:</label>

            <input-component
              v-mask="'##:##'"
              :type-input="'text'"
              v-model="formHour.hour"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <button-submit
              @submit="updateDateHour"
              :disabled="sendForm"
              class="w-100"
              :items="btnSubmit"
              typeButton="submit"
            />
          </row-col-component>
        </row-component>
      </div>
    </div>
  </div>

  <div class="modals position-fixed top-0 start-0 d-grid" v-if="modalObsClient">
    <div class="modal-client" v-if="modalObsClient">
      <div
        role="button"
        class="btn-close"
        @click="(modalObsClient = false), resetForm()"
      ></div>
      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
        >
          <QuillEditor
            theme="snow"
            :contentType="'html'"
            ref="myEditor"
            v-model:content="obsClient"
            style="min-height: 200px"
          />
        </row-col-component>
      </row-component>

      <row-component class="mt-5">
        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
        >
          <button-submit
            @submit="submitObsClient(obsClient)"
            :disabled="sendForm"
            class="w-100"
            :items="buttonObsClient"
          />
        </row-col-component>
      </row-component>
    </div>
  </div>

  <div class="modals position-fixed top-0 start-0 d-grid" v-if="modalAddObsTechnical">
    <div class="modal-client" v-if="modalAddObsTechnical">
      <div
        role="button"
        class="btn-close"
        @click="(modalAddObsTechnical = false), resetForm()"
      ></div>
      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
        >
          <QuillEditor
            theme="snow"
            :contentType="'html'"
            ref="myEditor"
            v-model:content="obsTechnical"
            style="min-height: 200px"
          />
        </row-col-component>
      </row-component>

      <row-component class="mt-5">
        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
        >
          <button-submit
            @submit="submitObsTechnical(obsTechnical)"
            :disabled="sendForm"
            class="w-100"
            :items="buttonObsClient"
          />
        </row-col-component>
      </row-component>
    </div>
  </div>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError" />
    <div class="clearfix mb-4" >
      <button-route class="float-end" :items="buttonRouteAdd" v-if="buttonAddRoute"/>
      <button-submit
        @click="printPDF"
        class="float-end border-1 "
        :items="{ icon: 'ti ti-printer' }"
        style="margin-right: 10px;"
    />
    </div>



    <div class="tabs effect-3" v-if="showView">
      <input checked type="radio" id="tab-1" name="tab-effect-3" />
      <span>
        <i class="ti ti-info-square-rounded"></i>
        <p>Informações</p>
      </span>

      <input @click="eventClick" type="radio" id="tab-2" name="tab-effect-3" />
      <span>
        <i class="ti ti ti-brand-line"></i>
        <p>Observações</p>
      </span>

      <input type="radio" id="tab-3" name="tab-effect-3" />
      <span>
        <i class="ti ti ti-calendar"></i>
        <p>Data</p>
      </span>

      <div class="line ease"></div>

      <div class="tab-content">
        <section id="tab-item-1">
          <form class="mt-5">
            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.client_name }"
              >
                <label>Cliente:</label>
                <div class="input-group">
                  <input
                    disabled
                    type="text"
                    v-model="formData.client_name"
                    class="form-control"
                  />
                  <span @click="modalSearchClient" class="buttonSubmit input-group-text">
                    <i class="ti ti-search"></i>
                  </span>
                </div>
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.phone }"
              >
                <label>Telefone:</label>
                <div class="input-group">
                  <input
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="formData.phone"
                    type="text"
                    class="form-control"
                  />
                  <button
                    type="button"
                    :disabled="formData.id === '' || formData.name === ''"
                    :tooltip="
                      formData.id === ''
                        ? 'Selecione um cliente cadastrado'
                        : '' || formData.name === ''
                        ? 'Selecione um cliente cadastrado'
                        : ''
                    "
                    @click="modalSearchClientPhone"
                    class="buttonSubmit input-group-text"
                  >
                    <i class="ti ti-search"></i>
                  </button>
                </div>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.service_id }"
              >
                <label>Serviço:</label>
                <Multiselect
                  v-model="formData.service_id"
                  :options="selectServiceList"
                  :searchable="true"
                  :noOptionsText="noOptionsText"
                  :noResultsText="noResultsText"
                />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                :class="{ formError: errors.technical_id }"
              >
                <label>Técnico Responsável:</label>
                <Multiselect
                  v-model="formData.technical_id"
                  :options="selectUserList"
                  :searchable="true"
                  :noOptionsText="noOptionsText"
                  :noResultsText="noResultsText"
                  track-by="value"
                  label="name"
                >
                  <template #option="{ option }">
                    <div class="d-flex align-items-center">
                      <img
                        v-if="option.icon"
                        :src="option.icon"
                        alt=""
                        class="rounded-circle me-2"
                        width="30"
                        height="30"
                      />
                      <span>{{ option.name }}</span>
                    </div>
                  </template>

                  <template #single-label="{ option }">
                    <div class="d-flex align-items-center">
                      <img
                        v-if="option.icon"
                        :src="option.icon"
                        alt=""
                        class="rounded-circle me-2"
                        width="25"
                        height="25"
                      />
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                </Multiselect>
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="line-division my-3">
            <h1 class="h1-text">
              <i class="calendar alternate icon"></i>
              Observações do cliente
            </h1>

            <div class="obsScroll">
              <div
                class="card p-3 bg-light my-3 overflow-auto"
                v-for="(obs, index) in formData.obsClient"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <img
                      v-if="obs.client_photo"
                      class="avatar__image mx-1"
                      :src="obs.client_photo"
                      alt=""
                    />
                    <img
                      v-else
                      class="avatar__image mx-1"
                      src="@/assets/images/avatar.png"
                      alt=""
                    />
                    {{ obs.client_name }}
                  </div>

                  <div>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-calendar-due"></i> {{ obs.date_create }}
                    </p>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-clock-filled"></i> {{ obs.hour_create }}
                    </p>
                  </div>
                </div>

                <div>
                  <p>{{ obs.obs }}</p>
                </div>
              </div>
            </div>

            <button-submit
              @click="modaladdObsClient"
              :disabled="sendForm"
              class="float-end mt-3"
              :items="buttonObsClient"
            />
          </div>

          <div class="line-division line-2">
            <h1 class="h1-text">
              <i class="calendar alternate icon"></i>
              Observações do técnico responsável
            </h1>
            <div class="obsScroll">
              <div
                class="card p-3 bg-light my-3 overflow-auto"
                v-for="(obs, index) in formData.obsTechnical"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <img
                      v-if="obs.user_photo"
                      class="avatar__image mx-1"
                      :src="obs.user_photo"
                      alt=""
                    />
                    <img
                      v-else
                      class="avatar__image mx-1"
                      src="@/assets/images/avatar.png"
                      alt=""
                    />
                    {{ obs.user_name }}
                  </div>

                  <div>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-calendar-due"></i> {{ obs.date_create }}
                    </p>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-clock-filled"></i> {{ obs.hour_create }}
                    </p>
                  </div>
                </div>

                <div>
                  <p>{{ obs.obs }}</p>
                </div>
              </div>
            </div>
            <button-submit
              @click="modalOpenTechnical"
              :disabled="sendForm"
              class="float-end"
              :items="buttonObsClient"
            />
          </div>
        </section>

        <section id="tab-item-3">
          <div>
            <div class="line-division my-3">
              <h1 class="h1-text">
                <i class="calendar alternate icon"></i>
                Datas
              </h1>
              <div class="clearfix">
                <button-submit
                  class="fs-5 float-end mb-4"
                  :items="buttonAddData"
                  @click="sectorOpen"
                />
              </div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 25%">Tipo</th>
                    <th scope="col" style="width: 25%">Data</th>
                    <th scope="col" style="width: 20%">Hora</th>
                    <th scope="col" style="width: 20%">Ações</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(dateHour, index) in formData.datesHours" :key="index">
                    <td data-label="Nome">
                      <img
                        v-if="dateHour.user_photo"
                        class="avatar__image mx-1"
                        :src="dateHour.user_photo"
                        alt=""
                      />
                      <img
                        v-else
                        class="avatar__image mx-1"
                        src="@/assets/images/avatar.png"
                        alt=""
                      />
                      {{ dateHour.user_name }}
                    </td>
                    <td data-label="Tipo">{{ dateHourTypeString(dateHour.type) }}</td>
                    <td data-label="Tipo">{{ dateHour.date }}</td>
                    <td data-label="Tipo">{{ dateHour.hour }}</td>
                    <td data-label="Ações">
                      <button-on-submit-edit @click="editDateHour(dateHour)" />
                      <button-delete @click="destroy(dateHour)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>

    <row-component>
      <row-col-component
        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
      >
        <button-submit
          @submit="submit"
          :disabled="sendForm"
          class="w-100"
          :items="btnSubmit"
        />
      </row-col-component>

      <row-col-component
        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
      >
        <button-route class="w-100" :items="btnRoute" />
      </row-col-component>
    </row-component>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonOnSubmitEdit from '@/components/buttons/ButtonOnSubmitEdit';
import { useToast } from 'vue-toastification';
import { mask } from 'vue-the-mask';
import { format } from 'date-fns';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import swal from 'sweetalert';
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast();

export default {
  directives: {
    mask
  },
  props: {
    id: {
      required: true
    }
  },

  components: {
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    ButtonSubmit,
    InputComponent,
    Multiselect,
    ButtonRoute,
    ButtonDelete,
    ButtonOnSubmitEdit,
    QuillEditor
  },

  created() {
    this.$store.dispatch('loadServiceMultiSelect');
    this.$store.dispatch('loadUserMultiSelect');
    this.loadBudget();
    this.checkACL();
  },

  data() {
    return {
      itensOne: {
        title: 'Editar',
        icon: 'ti-pencil-plus'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonSearch: {
        icon: 'ti-search',
        typeButton: 'button'
      },

      buttonConfirm: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Confirmar'
      },

      btnSubmit: {
        icon: 'ti-device-floppy',
        nameButton: 'Salvar',
        typeButton: 'button'
      },

      btnSubmitHour: {
        icon: 'ti-circle-plusy',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      btnRoute: {
        icon: 'ti-arrow-back-up',
        route: '/service-order/search',
        name: 'Voltar'
      },

      buttonAddData: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonObsClient: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      modalClient: false,
      modalPhone: false,
      modal_add_date_hour: false,
      edit_date_hour: false,
      showView: false,
      modalObsClient: false,
      modalAddObsTechnical: false,

      formData: {
        id: '',
        name: '',
        phone: '',
        service_id: '',
        technical_id: '',
        obs_client: '',
        obs_technical: '',
        obsClient: [],
        obsTechnical: []
      },

      obsClient: [],
      obsTechnical: [],

      formHour: {
        id: this.id,
        type: '',
        date: this.getCurrentDate(), // Formato YYYY-MM-DD
        hour: this.getCurrentHour() // Formato HH:mm
      },

      addObsClient: {
        id: '',
        obs_client: ''
      },

      client: {
        type: '2',
        name: '',
        cpf: '',
        cnpj: ''
      },

      errors: [],
      showError:false,
      buttonAddRoute:false,
      buttonRouteAdd: {
        icon: 'ti-plus',
        route: '/service-order/create',
      },
      sendForm:false
    };
  },

  methods: {
    submit() {
      if (!this.sendForm) {
        const formData = {
          id: this.id,
          client_id: this.formData.id,
          client_name: this.formData.client_name,
          phone: this.formData.phone,
          service_id: this.formData.service_id,
          technical_id: this.formData.technical_id,
          obs_client: this.formData.obs_client,
          obs_technical: this.formData.obs_technical
        };

        this.$store
          .dispatch('updateServiceOrder', formData)
          .then(() => {
            this.showError = false;
            this.errors = [];
            this.loadBudget();
            toast.success('Atualizado com sucesso!', { timeout: 1500 });
          })
          .catch(e => {
            this.showError = true;
            this.errors = e.response.data.errors;
            toast.error('Erro ao atualizar!', { timeout: 1500 });
          });
      }
    },

    submitHour() {
      if (!this.sendForm) {
        if (this.formHour.date) {
          this.formHour.date = format(new Date(this.formHour.date), 'dd/MM/yyyy');
        }

        this.$store
          .dispatch('storeOrderServiceAddDateHour', this.formHour)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.loadBudget();
            this.resetForm();
            this.modal_add_date_hour = false;

            toast.success('Cadastro efetuado com sucesso', { timeout: 2000 });
          })
          .catch(err => {
            this.showError = true;
            this.sendForm = false;
            this.errors = err.response.data.errors;
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },

    submitSearch() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    updateDateHour() {
      if (!this.sendForm) {
        if (this.formHour.date) {
          // Aqui você converte a data para o formato correto
          // Forçando a hora para 00:00:00 e evitando alterações por fuso horário
          const dateParts = this.formHour.date.split('/');
          const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00:00Z`;

          // Agora a data está em formato ISO (UTC), sem hora para evitar alterações
          this.formHour.date = format(new Date(formattedDate), 'dd/MM/yyyy');
        }

        this.$store
          .dispatch('updateServiceOrderDateHour', this.formHour)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.loadBudget();
            this.resetForm();
            this.edit_date_hour = false;
            toast.success('Data e Hora foram atualizados!', { timeout: 2000 });
          })
          .catch(err => {
            this.showError = true;
            this.sendForm = false;
            this.errors = err.response.data.errors;
            this.scrollToTop();
            toast.error('Seu formulário contém erros, verificar!', { timeout: 2000 });
          });
      }
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadClientsBudgetMaintenanceList', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', {
                  timeout: 2000
                });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadBudget() {
      this.$store
        .dispatch('loadServiceOrder', this.id)
        .then(r => {
          this.formData = {
            ...r,
            obs_client: r.obsClient.length > 0 ? r.obsClient[0].obs : '',
            obs_technical: r.obsTechnical.length > 0 ? r.obsTechnical[0].obs : ''
          };
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    submitObsClient(obsClient) {
      if (!this.sendForm) {
        const data = {
          id: this.id,
          obs_client: obsClient
        };

        this.$store
          .dispatch('createObsClient', data)
          .then(() => {
            this.modalObsClient = false;
            this.loadBudget();
            toast.success('Observação adicionada com sucesso');
          })
          .catch(() => {
            this.loadBudget();
            toast.error('Erro adicionar observação', { timeout: 2000 });
          });
      }
    },

    async submitObsTechnical(obsTechnical) {
      if (!this.sendForm) {
        const data = {
          id: this.id,
          obs_technical: obsTechnical
        };

        try {
          await this.$store.dispatch('createObsTechnical', data);
          this.modalAddObsTechnical = false;
          this.loadBudget();

          toast.success('Observação adicionada com sucesso');
        } catch (error) {
          this.loadBudget();
          toast.error('Erro adicionar observação', { timeout: 2000 });
        }
      }
    },

    modalSearchClient(params) {
      this.modalClient = true;
      this.client.name = '';
      this.client.cpf = '';
      this.client.cnpj = '';
      this.loadItems(1);
      if (params === 1) {
        this.modalClient = false;
        let client = this.selectClientList.find(
          element => element.value === this.formData.client_id
        );

        if (client) {
          this.formData.client_name = client.surname;
        }
      }
    },

    selectClient(data) {
      this.modalClient = false;
      this.formData.id = data.id;
      this.formData.name = data.surname;
      this.formData.cnpj = data.cnpj;
      this.formData.cpf = data.cpf;
    },

    modalSearchClientPhone() {
      this.modalPhone = !this.modalPhone;
      this.$store.dispatch('loadClientPhoneMultiSelect', this.formData.id);
    },

    sectorOpen() {
      this.modal_add_date_hour = true;
    },

    editDateHour(dateHour) {
      this.edit_date_hour = true;

      const validDate = dateHour.date.split('/').reverse().join('-'); // Converte 'DD/MM/YYYY' para 'YYYY-MM-DD'

      const dateObj = new Date(validDate + 'T00:00:00');

      if (!isNaN(dateObj.getTime())) {
        this.formHour = { ...dateHour, date: format(dateObj, 'yyyy-MM-dd') };
      } else {
        this.formHour = { ...dateHour, date: '' }; // Caso a data seja inválida
      }
    },

    resetForm() {
      this.formHour = {
        type: '',
        date: this.getCurrentDate(), // Formato YYYY-MM-DD
        hour: this.getCurrentHour()
      };
    },

    getCurrentDate() {
      const now = new Date();
      return now.toISOString().split('T')[0]; // Retorna YYYY-MM-DD
    },

    getCurrentHour() {
      const now = new Date();
      return now.toTimeString().slice(0, 5); // Retorna HH:mm
    },

    destroy(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyDateHour', item.id)
            .then(() => {
              this.loadBudget();
              toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
            })
            .catch(r => {
              let error = r.response.data.errors[0].toString();
              toast.warning(`${error}`, { timeout: 2000 });
            });
        }
      });
    },

    dateHourTypeString(value) {
      if (parseInt(value) === 1) return 'Visita';

      if (parseInt(value) === 2) return 'Serviço';
    },

    printPDF() {
      window.open(this.formData.link_budget_maintenance_pdf, '_blank');
    },

    modaladdObsClient() {
      this.modalObsClient = true;
    },

    modalOpenTechnical() {
      this.modalAddObsTechnical = true;
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 24)
        .then(r => {
          if (r.data.indexOf('service-order-edit') !== -1) {
            this.showView = true;

            if (r.data.indexOf('service-order-create') !== -1) {
              this.buttonAddRoute = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    object() {
      return this.$store.state.var.selectClientBudgetMaintenanceList;
    },

    selectClientPhoneList() {
      return this.$store.state.var.selectClientPhoneList;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectServiceList() {
      return this.$store.state.var.selectServiceList;
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },

    selectTypeDate() {
      return this.$store.state.var.selectTypeDate;
    },

    me() {
      return this.$store.state.auth.me;
    },

    params() {
      return {
        name: this.client.name,
        type: this.client.type,
        cpf: this.client.cpf,
        cnpj: this.client.cnpj
      };
    }
  }
};
</script>

<style scoped>
.avatar__image__div {
  width: 10%;
}

.buttonSubmit {
  background-color: #36c1bd;
  color: white;
  border-color: #36c1bd;
}

.modal-search-budget-template .btn-close,
.modal-search-client .btn-close,
.modal-search-email .btn-close,
.modal-search-phone .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.avatar__image {
  width: 25px;
}

.modal-search-client {
  z-index: 6;
  width: 80%;
  padding: 2%;
  height: 60%;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 5px 10px 12px 0 #a6a6a6;
}

.modal-search-budget-template,
.modal-search-email,
.modal-search-phone {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.line-division {
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

.line-2 {
  margin-top: 70px;
}

.h1-text {
  color: white;
  padding: 10px;
  margin-top: 1px;
  font-size: 16px;
  width: fit-content;
  background-color: #36c1bd;
}

.modals {
  z-index: 1024;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.send-sector {
  width: 35%;
  min-height: 30%;
  overflow-y: auto;
  padding: 40px 20px;
  background: #ffffff;
  position: relative;
}

.modal-client {
  width: 65%;
  min-height: 00%;
  overflow-y: auto;
  padding: 40px 20px 0 20px;
  background: #ffffff;
  position: relative;
}

.send-sector .btn-close,
.modal-client .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
  width: calc(100% / 3);
}

.color-main {
  color: #36c1bd;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 33.3%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
  left: 66.6%;
}

.obsScroll {
  max-height: 250px; /* Altura máxima */
  overflow-y: auto; /* Scroll vertical */
}

@media (max-width: 991px) {
  .modal-search-client,
  .modal-search-budget-template,
  .modal-search-email,
  .modal-search-phone {
    width: 95%;
    height: 80%;
  }

  .send-sector {
    width: 95%;
    min-height: 30%;
  }
}
</style>
