<template>
  <painel-component :itens="itensOne">
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Código:</label>
        <input-component :type-input="'text'" v-model="formData.code" />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Apelido:</label>
        <input-component :type-input="'text'" v-model="formData.surname" />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Serviço:</label>
        <Multiselect
          v-model="formData.service_id"
          :options="selectServiceList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'"
      >
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />
        <button-route v-if="buttonCheck.create" :items="buttonRoute" />
      </row-col-component>
    </row-component>

    <row-component> </row-component>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" style="width: 25%">Código:</th>
          <th scope="col" style="width: 25%">
            Nome Cliente <br />
            Apelido
          </th>
          <th
            scope="col"
            style="width: 20%"
            v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete"
          >
            Ações
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(budget, index) in service_order.data" :key="index">
          <td data-label="Código">{{ budget.code }}</td>

          <td data-label="Criador:">
            <img
              v-if="budget.photo_client"
              class="avatar-image mx-1"
              :src="budget.photo_client"
              alt=""
            />
            <img
              v-else
              class="avatar-image mx-1"
              src="@/assets/images/avatar.png"
              alt=""
            />
            {{ budget.name }} <br />
            {{ budget.surname }}
          </td>


          <td
            data-label="Ações"
            v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete"
          >
            <button-edit
              v-if="buttonCheck.edit"
              :route="'service-order-edit'"
              :id-param="budget.id"
            />
            <button-show
              v-if="buttonCheck.show"
              :route="'service-order-show'"
              :id-param="budget.id"
            />
            <button-delete v-if="buttonCheck.delete" @click="destroy(budget)" />
          </td>
        </tr>
      </tbody>
    </table>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonShow from '@/components/buttons/ButtonShow';

import swal from 'sweetalert';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'BudgetMaintenanceSearch',

  created() {
    this.$store.dispatch('updateNamePage', 'Ordem de serviço');
    this.$store.dispatch('loadServiceMultiSelect');

    this.checkACL();
    this.loadBudgetsMaintenance(1);
  },

  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
    ButtonDelete,
    ButtonShow
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'ti-list'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/service-order/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false,
        delete: false
      },

      formData: {
        code: '',
        surname: '',
        service_id: ''
      },

      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadBudgetsMaintenance(1);
      this.searchSubmit = true;
    },

    loadBudgetsMaintenance(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadOrderService', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', {
                  timeout: 2000
                });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadServicePriceMultiSelect(id, index) {
      this.$store.dispatch('loadServicePriceMultiSelect', id).then(r => {
        this.serviceAdd[index].unit_price = r;
        this.calcService(index);
      });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 24)
        .then(r => {
          if (r.data.indexOf('service-order-search') !== -1) {
            if (r.data.indexOf('service-order-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('service-order-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('service-order-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('service-order-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Cadastrado';

      if (parseInt(value) === 2) return 'Enviado para cliente';

      if (parseInt(value) === 3) return 'Aprovado Bene Piscinas';

      if (parseInt(value) === 4) return 'Aprovado Pelo Cliente';

      if (parseInt(value) === 5) return 'Cancelado';

      if (parseInt(value) === 6) return 'Enviado para entrega';

      if (parseInt(value) === 7) return 'Finalizado';
    },

    destroy(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          if (parseInt(item.status) === 5) {
            toast.warning(
              `Não é possível excluir o orçamento N° ${item.code}  , o mesmo já está cancelado.`,
              {
                timeout: 2000
              }
            );
          } else if (parseInt(item.status) === 7) {
            toast.warning(
              `Não é possível excluir o orçamento N° ${item.code}  , o mesmo já está finalizado.`,
              {
                timeout: 2000
              }
            );
          } else {
            this.$store
              .dispatch('destroyServiceOrder', item.id)
              .then(() => {
                this.loadBudgets(1);
                toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
              })
              .catch(r => {
                let error = r.response.data.errors[0].toString();
                toast.warning(`${error}`, { timeout: 2000 });
              });
          }
        }
      });
    }
  },

  computed: {
    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectServiceList() {
      return this.$store.state.var.selectServiceList;
    },

    service_order() {
      return this.$store.state.service_order.items;
    },

    params() {
      return {
        code: this.formData.code,
        surname: this.formData.surname,
        service_id: this.formData.service_id
      };
    },

    me() {
      return this.$store.state.auth.me;
    }
  }
};
</script>

<style>
.avatar-image {
  width: 30px;
  border-radius: 50%;
}
</style>
