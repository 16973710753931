<template>
  <painel-component :itens="itemsOne">
    <form>
      <row-component>
        <row-col-component  class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Código:</label>
          <input-component v-model="formData.code" placeholder="Código"  type-input="'text"/>
        </row-col-component>

        <row-col-component  class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" >
          <label>Vendedor:</label>
          <Multiselect v-model="formData.creator_user_id" :options="clients" />
        </row-col-component>

        <row-col-component  class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectOpenOrClose" placeholder="Status" />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component  class-row-col="" class-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />

        </row-col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">Código</th>
                <th scope="col" style="width: 15%">Vendedor</th>
                <th scope="col" style="width: 15%">Status</th>
                <th scope="col" style="width: 15%">
                  Data Abertura <br />
                  Hora
                </th>
                <th scope="col" style="width: 15%">
                  Data Fechamento <br />
                  Hora
                </th>
                <th scope="col" style="width: 12%">
                  Valor Venda <br />
                  Valor Caixa
                </th>
                <th scope="col" style="width: 15%">Ações</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td class="row-consultora" data-label="Consultora" v-if="data.user_creater">
                  <div>
                    <span>{{ data.user_creater }}<br /></span>

                    <br />
                    <img class="avatar__image "  :src="data.user_photo" alt=""/>
                  </div>
                </td>
                <td class="row-consultora" data-label="Consultora" v-else>
                  <div>
                    <span>N/I <br /></span>
                    <img class="avatar__image "  src="@/assets/images/avatar.png" alt=""/>
                    {{ data.user_creater }}
                  </div>
                </td>

                <td data-label="Status" v-if="data.status == 1">Aberto</td>
                <td data-label="Status" v-if="data.status == 2">Fechado</td>

                <td>
                  <div>{{ data.dateHourClose.split(' - ')[0] }}</div>
                  <div>{{ data.dateHourClose.split(' - ')[1] }}</div>
                </td>

                <td>
                  <div>{{ data.dateHourOpen.split(' - ')[0] }}</div>
                  <div>{{ data.dateHourOpen.split(' - ')[1] }}</div>
                </td>

                <td>
                  R$ {{ data.totalSale }} <br />
                  R$ {{ data.boxTotal }}
                </td>

                <td data-label="Ações">
                  <button-show :idParam="data.id" class="m-1" route="pdv-box-historic-show" />
                  <button-submit type="'button'" @click="openPDF(data.linkPrint)" :items="itemsButton"  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from "@/components/row/RowColComponent.vue";
import InputComponent from '@/components/form/InputComponent';
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import ButtonShow from '@/components/buttons/ButtonShow.vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'BoxHistoricSearch',

  components: {
    ButtonPagination,
    ButtonSubmit,
    Multiselect,
    InputComponent,
    RowColComponent,
    RowComponent,
    PainelComponent,
    ButtonShow
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Histórico Caixa');
   this.checkACL();
   this.loadUsers();
    this.loadItems(1);

  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'ti-list'
      },

      buttonCheck: {
        create: false,
        edit: false
      },

      buttonSubmit: {
        icon: 'ti-search'
      },


      itemsButton: {
        icon: 'ti-printer'
      },

      formData: {
        status: '',
        creator_user_id: '',
        code: '',
        user_creater: '',
        user_photo: '',
        boxTotal: '',
        dateHourOpen: '',
        dateHourClose: ''
      },

      viewPage: false,
      searchSubmit: false,

      clients: []
    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadBoxHistorics', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadUsers(page) {
      this.$store
        .dispatch('loadUsers', { ...this.params, page })
        .then(r => {
          // Mapear os nomes dos clientes para mostrar no select
          this.clients = r.data.map(client => ({
            label: client.name,
            value: client.id
          }));
        })
        .catch(() => {});
    },

    // Permissions
    async checkACL() {
      this.$store.dispatch('ACLitens', 25)
          .then((r) => {
            if (r.data.indexOf('pdv-box-historic') !== -1) {
                  this.viewPage = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },


    statusString(value) {
      if (parseInt(value) === 1) return 'Ativo';
      return 'Inativo';
    },

    openPDF(link) {
      if (link) {
        window.open(link, '_blank');
      }
    }
  },

  computed: {
    selectOpenOrClose() {
      return this.$store.state.var.selectOpenOrClose;
    },

    object() {
        return this.$store.state.box_historic.items
    },

    params() {
      return {
        code: this.formData.code,
        creator_user_id: this.formData.creator_user_id,
        status: this.formData.status
      };
    }
  }
};
</script>

<style>
.user-photo {
  width: 30px;
  border-radius: 50%;
}
</style>

<style scoped>
@media (max-width: 991px) {
  .row-consultora {
    display: flex;
    justify-content: space-between;
  }

  .row-consultora div {
    display: flex;
  }

  .row-consultora div span {
    margin-top: 8px;
  }

  .row-consultora div img {
    margin-left: 10px;
  }
}
</style>
