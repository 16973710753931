<template>
  <painel-component :itens="itemsOne">
    <form>
      <row-component>
        <row-col-component :class-row-col="'ol-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <label>N°:</label>
          <input-component v-model="formData.code" placeholder="Código" :type-input="'text'"/>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <label>Vendedor:</label>
          <Multiselect v-model="formData.consultant_id" :options="clients"/>
        </row-col-component>


        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectOpenOrClose" placeholder="Status"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
        </row-col-component>
      </row-component>
    </form>

    <div class="bg-body-secondaryd">
      <div class="bg-white">
        <div class="__table">
          <table class="table table-striped" v-if="viewPage">
            <thead>
            <tr>
              <th scope="col" style="width: 15%">N°</th>
              <th scope="col" style="width: 20%">
                Cliente <br/>
                Vendedor
              </th>
              <th scope="col" style="width: 15%">Status</th>
              <th scope="col" style="width: 15%">
                Data <br/>
                Hora
              </th>
              <th scope="col" style="width: 25%">Valor total</th>
              <th scope="col" style="width: 15%">Ações</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, i) in object.data" :key="i">
              <td data-label="Código">{{ data.code }}</td>


              <td class="row-consultora" data-label="Vendedor">

                <div v-if="data.name_client">
                  {{ data.name_client }}
                </div>

                <div v-else>
                  N/I
                </div>


                <div v-if="data.user_creater">
                  <span>{{ data.user_creater }}</span>
                  <br/>
                  <img :src="data.user_photo" class="user-photo" alt="Foto consultora"/>
                </div>


              </td>


              <td data-label="Status" v-if="data.status == 1">Aberto</td>
              <td data-label="Status" v-if="data.status == 2">Em pagamento</td>
              <td data-label="Status" v-if="data.status == 3">Cancelado</td>
              <td data-label="Status" v-if="data.status == 4">Finalizado</td>

              <td data-label="Data e Hora">
                <div>{{ data.dateOpen }}</div>
                <div>{{ data.hourOpen }}</div>
              </td>


              <td data-label="Valor total" v-if="data.type_discount ==null">
                R$ {{ data.priceTotalAddition }}
              </td>

              <td data-label="Valor total" v-if="data.type_discount ==1">
                R$ {{ data.priceTotalAddition }}
                <br>
                R$ {{ data.priceTotalDiscount }}
                <span class="badge">-{{ data.discount }}%</span>
              </td>

              <td data-label="Valor total" v-if="data.type_discount ==2">
                R$ {{ data.priceTotalAddition }}
                <br>
                R$ {{ data.priceTotalDiscount }}
                <span class="badge">-R$ {{ data.discount_money }}</span>
              </td>

              <td data-label="Ações">
                <button-show :idParam="data.id" class="m-1" route="pdv-sale-historic-show"/>
                <button-submit type="'button'" @click="openPDF(data.linkPrint)" :items="itemsButton"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import ButtonShow from '@/components/buttons/ButtonShow.vue';
import {useToast} from 'vue-toastification';
import RowColComponent from "@/components/row/RowColComponent.vue";

const toast = useToast();

export default {
  name: 'SalesHistoricSearch',

  components: {
    RowColComponent,
    ButtonPagination,
    ButtonSubmit,
    Multiselect,
    InputComponent,
    RowComponent,
    PainelComponent,
    // LoaderComponent,
    ButtonShow
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Pedidos Caixa');
    this.checkACL();
    this.loadUsers(1)
    this.loadItems(1);

  },

  data() {
    return {
      itemsOne: {
        title: 'Pesquisar',
        icon: 'ti-list'
      },

      buttonCheck: {
        create: false,
        edit: false
      },
      buttonSubmit: {
        icon: 'ti-search'
      },

      formData: {
        status: '',
        consultant_id: '',
        code: '',
        user_creater: '',
        user_photo: '',
        boxTotal: '',
        dateHourOpen: '',
        dateHourClose: ''
      },

      viewPage: false,
      searchSubmit: false,

      clients: [],
      itemsButton: {
        icon: 'ti-printer'
      },


    };
  },

  methods: {
    submit() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
            .dispatch('loadSalesHistorics', {...this.params, page})
            .then(r => {
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.data.length === 0) {
                  toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 2000});
                }
              }
            })
            .catch(() => {
              this.searchSubmit = false;
            });
      }
    },

    loadUsers(page) {
      this.$store
          .dispatch('loadUsers', {...this.params, page})
          .then(r => {
            // Mapear os nomes dos clientes para mostrar no select
            this.clients = r.data.map(client => ({
              label: client.name,
              value: client.id
            }));
          })
          .catch(() => {
          });
    },


    // Permissions
    async checkACL() {
      this.$store.dispatch('ACLitens', 25)
          .then((r) => {
            if (r.data.indexOf('pdv-sales-historic') !== -1) {
              this.viewPage = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },


    statusString(value) {
      if (parseInt(value) === 1) return 'Ativo';
      return 'Inativo';
    },

    openPDF(link) {
      if (link) {
        window.open(link, '_blank');
      }
    }
  },

  computed: {
    selectOpenOrClose() {
      return this.$store.state.var.selectOpenOrClose;
    },

    object() {
      return this.$store.state.sales_historic.items;
    },

    params() {
      return {
        code: this.formData.code,
        consultant_id: this.formData.consultant_id,
        client_id: this.formData.client_id,
        status: this.formData.status
      };
    }
  }
};
</script>

<style>
.user-photo {
  width: 30px;
  border-radius: 50%;
}
</style>

<style scoped>
@media (max-width: 991px) {
  .row-consultora {
    display: flex;
    justify-content: space-between;
  }

  .row-consultora div {
    display: flex;
  }

  .row-consultora div span {
    margin-top: 8px;
  }

  .row-consultora div img {
    margin-left: 10px;
  }
}
</style>
