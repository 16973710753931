<template>
  <painel-component :itens="itemsOne">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="pt-3 text-uppercase">
        <h5 class="text-dark">
          N° {{ formData.code }} |
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 1">Aberto</span>
          <span class="badge rounded-pill text-bg-dark" v-if="formData.status == 2">Fechado</span>
        </h5>
        <div class="d-flex flex-column">
          <small>Hora de abertura: {{ formData.dateHourOpen }}</small>
          <small v-if="formData.status == 2">Hora de fechamento: {{ formData.dateHourClose }}</small>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row align-items-center m-3">
        <button-route class="mx-1" :items="itemsTwo" />
        <button-submit type="'button'" @click="openPDF(formData.linkPrint)" :items="itemsButton"  />

      </div>
    </div>


    <div class="card-infos mb-3">
      <RowComponent>
        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <div class="card-home">
            <div class="desc">
              <p>Dinheiro</p>
              <p>R$ {{ formData.balanceMoney }}</p>
            </div>
            <div class="icon">
              <i class="ti ti-cash-banknote"></i>
            </div>
          </div>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <div class="card-home">
            <div class="desc">
              <p>PIX</p>
              <p>R$ {{ formData.balancePix }}</p>
            </div>
            <div class="icon">
              <i class="ti ti-coin"></i>
            </div>
          </div>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <div class="card-home">
            <div class="desc">
              <p>Cartão de Crédito</p>
              <p>R$ {{ formData.balanceCredit }}</p>
            </div>
            <div class="icon">
              <i class="ti ti-credit-card"></i>
            </div>
          </div>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
          <div class="card-home">
            <div class="desc">
              <p>Cartão de Débito</p>
              <p>R$ {{ formData.balanceDebit }}</p>
            </div>
            <div class="icon">
              <i class="ti ti-credit-card"></i>
            </div>
          </div>
        </row-col-component>
      </RowComponent>
    </div>
    <!-- <div class="mt-4 spacing-border"></div> -->

    <div class="card mt-4 rounded-0 text-uppercase">
      <div class="p-3 d-flex flex-column align-items-center align-items-md-start">
        <h5 class="text-uppercase">Consultora | Cliente</h5>
        <div class="d-flex align-items-center">
          <!-- <img class="profileImg" :src="formData.user_photo" alt="foto_de_perfil"> -->
          <img class="profileImg" :src="formData.user_photo" alt="foto_de_perfil" />
          <div>
            <small v-if="formData.user_creater">{{ formData.user_creater }} </small>
            <small v-else>N/I</small>
            |
            <small v-if="formData.name_client">{{ formData.name_client }} </small>
            <small v-else>N/I</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4 rounded-0">
      <h5 class="p-3 text-uppercase">Movimentação</h5>
      <div class="table-responsive" style="max-height: 600px; overflow-y: auto">
        <table class="table table-hover">
          <thead class="bg-dark text-white">
          <tr>
            <th scope="col">Tipo</th>
            <th scope="col">Motivo</th>
            <th scope="col">Data - Hora (Aberto)</th>
            <th scope="col">Forma de pagamento</th>
            <th scope="col">Valor</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in formData.entrance_and_exit" :key="item.id">
            <th v-if="item.type == 1"><i class="arrow alternate circle up icon green" />Entrada</th>
            <th v-else><i class="arrow alternate circle down icon red" /> Saída</th>
            <th>{{ item.reason }}</th>
            <td>{{ item.dateHourCreate }}</td>
            <td v-if="item.type_payment == 1">Dinheiro</td>
            <td v-if="item.type_payment == 2">PIX</td>
            <td v-if="item.type_payment == 3">Cartão de Crédito</td>
            <td v-if="item.type_payment == 4">Cartão de Débito</td>
            <td>R$ {{ item.price }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </painel-component>

  <!-- {{ formData }} -->
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from "@/components/row/RowColComponent.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";

export default {
  name: 'BoxHistoricShow',

  components: {
    ButtonRoute,
    ButtonSubmit,
    RowColComponent,
    PainelComponent,
    RowComponent,
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Histórico Caixa');
    this.checkACL();
    this.loadItem(1);
  },

  data() {
    return {
      itemsOne: {
        title: 'Caixa',
        icon: "ti-eye"
      },

      itemsTwo: {
        route: '/pdv-box-dashboard/search',
        icon: 'ti-arrow-back-up',
        name: ''
      },

      itemsButton: {
        icon: 'ti-printer'
      },

      formData: {},

      viewPage: false,

      clients: []
    };
  },

  methods: {
    loadItem() {
      this.$store
          .dispatch('loadDashboardHistoric', this.id)
          .then(r => {
            this.formData = r;
            this.formData.id = this.id;
          })
          .catch(() => {});
    },

    // Permissions
    async checkACL() {
      this.$store.dispatch('ACLitens', 25)
          .then((r) => {
            if (r.data.indexOf('pdv-box-dashboard') !== -1) {
              this.viewPage = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    openPDF(link) {
      if (link) {
        window.open(link, '_blank');
      }
    }

  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    object() {
      return this.$store.state.box_historic.items;
    },

    params() {
      return {
        code: this.formData.code,
        creator_user_id: this.formData.creator_user_id,
        status: this.formData.status
      };
    }
  }
};
</script>

<style scoped>
.spacing-border {
  border: 1px solid #000;
}

.profileImg {
  width: 50px;
  margin: 15px;
  border-radius: 50%;
}

.payment-option {
  height: 100px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ffffff;
}

.payment-option div {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.payment-option .icon-card {
  font-size: 38px;
}

.btn-show {
  display: flex;
  justify-content: space-between;
}

.no-padding {
  margin: 0;
  padding: 0;
  width: 24%;
}

i.icon {
  margin: 0 25px 0 0;
  width: 0;
  height: 0;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

i.icon {
  margin: 0 25px 0 0;
  width: 0;
  height: 0;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

.hour button {
  height: 25px;
}

.card-infos {
  margin: 20px;
}

.card-infos .card-home {
  border: 0;
  width: 100%;
  height: 95px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #000;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
}

.card-infos .card-home .icon {
  width: 0%;
  background-color: transparent;
  color: #000000;
  font-size: 3.5rem;
  margin-right: 85px;
}

.card-infos .card-home .desc {
  padding: 0 20px;
}

.card-infos .card-home .desc p {
  margin: 0;
  color: #ababab;
  font-size: 20px;
  text-transform: uppercase;
}

.card-infos .card-home .desc p:last-child {
  margin: 0;
  color: #000000;
  font-weight: bold;
}

.credit .card .icon {
  background-color: red;
}

@media (max-width: 1290px) {
  .card-infos .card-home .desc {
    padding: 0 0 0 10px;
  }

  .card-infos .card-home .desc p {
    font-size: 16px;
  }

  .card-infos .card-home .icon {
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .headerTitle {
    flex-direction: column;
    height: auto;
    padding: 10px;
    grid-gap: 10px 0;
  }

  .card-infos .card-home {
    width: 100%;
  }

  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 16px;
  }

  .card-infos .card-home .desc {
    padding: 20px;
  }
}

@media (max-width: 428px) {
  .card-infos .card-home .desc p {
    margin: 0;
    font-size: 20px;
  }
}
</style>
