<template>
  <div class="modal-search-client" v-if="modalClient">
    <div class="btn-close" @click="modalClient = !modalClient"></div>
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
      >
        <input v-model="client.type" type="radio" name="docs" id="tp-one" value="1" />
        <label for="tp-one">CPF:</label>

        <input
          v-model="client.type"
          type="radio"
          name="docs"
          id="tp-two"
          value="2"
          checked
        />
        <label for="tp-two">CNPJ:</label>
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
      >
        <label>Nome:</label>
        <input-component v-model="client.name" type-input="text" />
      </row-col-component>

      <row-col-component
        v-if="client.type === '1'"
        :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>CPF:</label>
        <input-component
          v-mask="'###.###.###-##'"
          v-model="client.cpf"
          type-input="text"
        />
      </row-col-component>

      <row-col-component
        v-if="client.type === '2'"
        :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>CNPJ:</label>
        <input-component
          v-mask="'##.###.###/####-##'"
          v-model="client.cnpj"
          type-input="text"
        />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'"
      >
        <button-submit @click="submitSearch" :items="buttonSearch" />
      </row-col-component>
    </row-component>

    <div class="mt-4">
      <row-component>
        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          v-for="(data, index) in object.data"
          :key="index"
        >
          <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
            <div class="h-100 avatar__image__div">
              <img
                class="avatar__image w-100"
                v-if="data.photo"
                :src="data.photo"
                alt=""
              />
              <img
                class="avatar__image w-100"
                v-else
                src="@/assets/images/avatar.png"
                alt=""
              />
            </div>
            <div class="ms-3 w-75">
              <p class="mb-1 text-truncate">{{ data.name }}</p>
              <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
            </div>
            <div class="position-absolute end-0 me-1 me-lg-3">
              <button-submit @click="selectClient(data)" :items="buttonAdd" />
            </div>
          </div>
        </row-col-component>
      </row-component>

    </div>
  </div>

  <div class="modal-search-phone" v-if="modalPhone">
    <div class="btn-close" @click="modalPhone = !modalPhone"></div>
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
      >
        <label>Telefone:</label>
        <Multiselect
          v-model="formData.phone"
          :options="selectClientPhoneList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchClientPhone" :items="buttonConfirm" />
  </div>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError" />

    <form>
      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.client_name }"
        >
          <label>Cliente:</label>
          <div class="input-group">
            <input disabled type="text" v-model="formData.name" class="form-control" />
            <span @click="modalSearchClient" class="buttonSubmit input-group-text">
              <i class="ti ti-search"></i>
            </span>
          </div>
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.phone }"
        >
          <label>Telefone:</label>
          <div class="input-group">
            <input
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="formData.phone"
              type="text"
              class="form-control"
            />
            <button
              type="button"
              :disabled="formData.id === '' || formData.name === ''"
              :tooltip="
                formData.id === ''
                  ? 'Selecione um cliente cadastrado'
                  : '' || formData.name === ''
                  ? 'Selecione um cliente cadastrado'
                  : ''
              "
              @click="modalSearchClientPhone"
              class="buttonSubmit input-group-text"
            >
              <i class="ti ti-search"></i>
            </button>
          </div>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.service_id }"
        >
          <label>Serviço:</label>
          <Multiselect
            v-model="formData.service_id"
            :options="selectServiceList"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.technical_id }"
        >
          <label>Técnico Responsável:</label>
          <Multiselect
            v-model="formData.technical_id"
            :options="selectUserList"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            track-by="value"
            label="name"
          >
            <template #option="{ option }">
              <div class="d-flex align-items-center">
                <img
                  v-if="option.icon"
                  :src="option.icon"
                  alt=""
                  class="rounded-circle me-2"
                  width="30"
                  height="30"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>

            <template #single-label="{ option }">
              <div class="d-flex align-items-center">
                <img
                  v-if="option.icon"
                  :src="option.icon"
                  alt=""
                  class="rounded-circle me-2"
                  width="25"
                  height="25"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>
          </Multiselect>
        </row-col-component>
      </row-component>

      <div class="line-division my-3">
        <h1 class="h1-text">
          <i class="calendar alternate icon"></i>
          Observações do cliente
        </h1>
        <QuillEditor
          theme="snow"
          :contentType="'html'"
          ref="myEditor"
          v-model:content="formData.obs_client"
          style="min-height: 200px"
        />
      </div>

      <div class="line-division my-3">
        <h1 class="h1-text">
          <i class="calendar alternate icon"></i>
          Observações do técnico responsável
        </h1>
        <QuillEditor
          theme="snow"
          :contentType="'html'"
          ref="myEditor"
          v-model:content="formData.obs_technical"
          style="min-height: 200px"
        />
      </div>

      <row-component>
        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <button-submit
            @submit="submit"
            :disabled="sendForm"
            class="w-100"
            :items="btnSubmit"
          />
        </row-col-component>

        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <button-route class="w-100" :items="btnRoute" />
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useToast } from 'vue-toastification';
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import { mask } from 'vue-the-mask';


const toast = useToast();

export default {
  components: {
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    ButtonSubmit,
    InputComponent,
    Multiselect,
    ButtonRoute,
    QuillEditor
  },

  created() {
    this.checkACL()
    this.$store.dispatch('loadServiceMultiSelect');
    this.$store.dispatch('loadUserMultiSelect');
  },

  directives: {
    mask
  },

  data() {
    return {
      itensOne: {
        title: 'Adicionar',
        icon: 'ti-pencil-plus'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonSearch: {
        icon: 'ti-search',
        typeButton: 'button'
      },

      buttonConfirm: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Confirmar'
      },

      btnSubmit: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      btnRoute: {
        icon: 'ti-arrow-back-up',
        route: '/service-order/search',
        name: 'Voltar'
      },

      modalClient: false,
      modalPhone: false,

      formData: {
        id: '',
        name: '',
        phone: '',
        service_id: '',
        technical_id: '',
        obs_client: '',
        obs_technical: ''
      },

      client: {
        type: '2',
        name: '',
        cpf: '',
        cnpj: ''
      },

      errors: [],
      showError: false

    };
  },
  methods: {
    submit() {
      if (!this.sendForm) {
        const formData = {
          client_id: this.formData.id,
          client_name: this.formData.name,
          phone: this.formData.phone,
          service_id: this.formData.service_id,
          technical_id: this.formData.technical_id,
          obs_client: this.formData.obs_client,
          obs_technical: this.formData.obs_technical
        };
        this.$store
          .dispatch('storeServiceOrder', formData)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Cadastro efetuado com sucesso', { timeout: 2000 });
            this.$router.push({
              name: 'service-order-edit',
              params: { id: r.data }
            });
          })
          .catch(err => {
            this.showError = true;
            this.sendForm = false;
            this.errors = err.response.data.errors;
            this.scrollToTop();
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },

    submitSearch() {
      this.loadItems(1);
      this.searchSubmit = true;
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadClientsBudgetMaintenanceList', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', {
                  timeout: 2000
                });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    modalSearchClient(params) {
      this.modalClient = true;
      this.client.name = '';
      this.client.cpf = '';
      this.client.cnpj = '';
      this.loadItems(1);
      if (params === 1) {
        this.modalClient = false;
        let client = this.selectClientList.find(
          element => element.value === this.formData.client_id
        );

        if (client) {
          this.formData.client_name = client.surname;
        }
      }
    },

    selectClient(data) {
      this.modalClient = false;
      this.formData.id = data.id;
      this.formData.name = data.surname;
      this.formData.cnpj = data.cnpj;
      this.formData.cpf = data.cpf;
    },

    modalSearchClientPhone() {
      this.modalPhone = !this.modalPhone;
      this.$store.dispatch('loadClientPhoneMultiSelect', this.formData.id);
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 24)
          .then((r) => {
            if (r.data.indexOf('service-order-create') !== -1) {
              this.showView = true
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },

  computed: {
    object() {
      return this.$store.state.var.selectClientBudgetMaintenanceList;
    },

    selectClientPhoneList() {
      return this.$store.state.var.selectClientPhoneList;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectServiceList() {
      return this.$store.state.var.selectServiceList;
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },

    me() {
      return this.$store.state.auth.me;
    },

    params() {
      return {
        name: this.client.name,
        type: this.client.type,
        cpf: this.client.cpf,
        cnpj: this.client.cnpj
      };
    }
  }
};
</script>

<style scoped>
.avatar__image__div {
  width: 10%;
}

.buttonSubmit {
  background-color: #36c1bd;
  color: white;
  border-color: #36c1bd;
}

.modal-search-budget-template .btn-close,
.modal-search-client .btn-close,
.modal-search-email .btn-close,
.modal-search-phone .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.modal-search-client {
  z-index: 99;
  width: 80%;
  padding: 2%;
  height: 60%;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 5px 10px 12px 0 #a6a6a6;
}

.modal-search-budget-template,
.modal-search-email,
.modal-search-phone {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.line-division {
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

.h1-text {
  color: white;
  padding: 10px;
  margin-top: 1px;
  font-size: 16px;
  width: fit-content;
  background-color: #36c1bd;
}

@media (max-width: 991px) {
  .modal-search-client,
  .modal-search-budget-template,
  .modal-search-email,
  .modal-search-phone {
    width: 95%;
    height: 80%;
  }
}
</style>
