<template>

  <div class="modal-w  position-fixed top-0 start-0 d-grid" v-if="sector_modal">
    <div class="send-sector" v-if="sector_modal">
      <div role="button" class="btn-close" @click="sector_modal = false"></div>
      <div class="mt-3">
        <row-component>
          <template v-for="(data, index) in sectors" :key="index">
            <row-col-component
              class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
              v-if="parseInt(formData.status) !== data.id"
            >
              <div
                class="d-flex align-items-center bg-body-secondary p-3 position-relative"
              >
                <div class="ms-3 w-75">
                  <p class="mb-1 text-truncate">{{ data.name }}</p>
                </div>
                <div class="position-absolute end-0 me-1 me-lg-3">
                  <button-submit
                    @click="submitStatus(data.id)"
                    :items="buttonActions[0]"
                  />
                </div>
              </div>
            </row-col-component>
          </template>
        </row-component>
      </div>
    </div>
  </div>

  <painel-component :itens="itensOne">
    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
      >
        <label>Código:</label>
        <input-component :type-input="'text'" v-model="formData.code" />
      </row-col-component>


      <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
      >
        <label>Data:</label>
        <input-component v-mask="'##/##/####'" v-model="formData.date" type-input="text"/>
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Nome Cliente:</label>
        <input-component :type-input="'text'" v-model="formData.name" />
      </row-col-component>




      <row-col-component
        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Apelido:</label>
        <input-component :type-input="'text'" v-model="formData.surname" />
      </row-col-component>
    </row-component>

    <row-component>
      <row-col-component
        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
      >
        <label>Nome Orçamento:</label>
        <Multiselect
          v-model="formData.name_budget_id"
          :options="selectNameBudgetList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component
          :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Vendedor:</label>
        <Multiselect
            v-model="formData.creator_user_id"
            :options="selectUserList"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            track-by="value"
            label="name"

        >
          <template #option="{ option }">
            <div class="d-flex align-items-center">
              <img
                  v-if="option.icon"
                  :src="option.icon"
                  alt=""
                  class="rounded-circle me-2"
                  width="30"
                  height="30"
              />
              <span>{{ option.name }}</span>
            </div>
          </template>

          <template #single-label="{ option }">
            <div class="d-flex align-items-center">
              <img
                  v-if="option.icon"
                  :src="option.icon"
                  alt=""
                  class="rounded-circle me-2"
                  width="25"
                  height="25"
              />
              <span>{{ option.name }}</span>
            </div>
          </template>
        </Multiselect>

      </row-col-component>



      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
      >
        <label>Status:</label>
        <Multiselect
          v-model="formData.status"
          :options="selectStatusBudget"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'"
      >
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />
        <button-route v-if="buttonCheck.create" :items="buttonRoute" />
      </row-col-component>
    </row-component>

    <row-component> </row-component>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" style="width: 5%">Código:</th>
          <th scope="col" style="width: 5%">Data:</th>
          <th scope="col" style="width: 25%">
            Nome Cliente <br />
            Apelido
          </th>
          <th scope="col" style="width: 25%">Nome orçamento: <br> Vendedor:</th>
          <th scope="col" style="width: 15%">Status:</th>
          <th
            scope="col"
            style="width: 20%"
            v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete"
          >
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(budget, index) in budget.data" :key="index">
          <td data-label="Código">{{ budget.code }}  </td>
          <td data-label="Data">{{ budget.date }}  </td>
          <td data-label="Criador:">
            <img
              v-if="budget.photo_client"
              class="avatar__image mx-1"
              :src="budget.photo_client"
              alt=""
            />
            <img
              v-else
              class="avatar__image mx-1"
              src="@/assets/images/avatar.png"
              alt=""
            />
            {{ budget.name }} <br />
            {{ budget.surname }}
          </td>

          <td data-label="Nome orçamento | Vendedor" v-if="budget.name_budget_id != null">
            {{ budget.name_budget }} <br> {{budget.name_user}}
          </td>

          <td data-label="Nome orçamento | Vendedor" v-else>{{ budget.name }}  <br> {{budget.name_user}}</td>

          <td data-label="Status">{{ statusString(budget.status) }}</td>

          <td
            data-label="Ações"
            v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete"
          >
            <button-submit
              v-if="parseInt(budget.status) !== 5 && parseInt(budget.status) !== 7"
              class="mx-1"
              @click="sectorOpen(budget.id)"
              tooltip="Atualizar Status"
              :items="buttonActions[1]"
            />
            <button-edit
              v-if="buttonCheck.edit"
              :route="'budget-edit'"
              :id-param="budget.id"
            />
            <button-delete v-if="buttonCheck.delete" @click="destroy(budget)" />
            <button-show
              v-if="buttonCheck.show"
              :route="'budget-show'"
              :id-param="budget.id"
            />
            <button-submit
              class="efc-disabled"
              v-if="!budget.budget_delivery_id"
              @click="deliveryCreate(budget.id, budget.client_id)"
              :items="buttonDelivery"
            />
            <button-submit v-else @click="deliveryEdit(budget)" :items="buttonDelivery" />
          </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="budget" :offset="3" @paginate="loadBudgets" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import swal from 'sweetalert';
import { useToast } from 'vue-toastification';
import {mask} from "vue-the-mask";
import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const toast = useToast();

export default {
  name: 'BudgetSearch',



  mounted() {
    window.addEventListener('keyup', this.submit);
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Orçamento');
    this.$store.dispatch('loadNameBudgetList');
    this.$store.dispatch('loadUserMultiSelect');
    this.checkACL();
    this.loadBudgets(1);
  },

  directives: {
    mask
  },




  components: {
    ButtonDelete,
    ButtonPagination,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
    ButtonShow
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'ti-list'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/budget/create'
      },

      buttonDelivery: {
        icon: 'ti-truck-delivery',
        route: '/budget/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false,
        delete: false
      },

      formData: {
        code: '',
        name: '',
        surname: '',
        name_budget: '',
        status: '',
        name_budget_id: '',
        date:'',
        creator_user_id:''
      },

      buttonActions: [{ icon: 'ti-send' }, { icon: 'ti-circle-check' }],

      sectors: [
        { name: 'Cadastrado', id: 1 },
        { name: 'Enviado para cliente', id: 2 },
        { name: 'Aprovado Bene Piscinas', id: 3 },
        { name: 'Aprovado Pelo Cliente', id: 4 },
        { name: 'Cancelado', id: 5 },
        { name: 'Enviado para entrega', id: 6 },
        { name: 'Finalizado', id: 7 }
      ],
      sector_modal: false,
      id: '',

      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadBudgets(1);
      this.searchSubmit = true;
    },

    statusString(value) {
      if (parseInt(value) === 1) return 'Cadastrado';

      if (parseInt(value) === 2) return 'Enviado para cliente';

      if (parseInt(value) === 3) return 'Aprovado Bene Piscinas';

      if (parseInt(value) === 4) return 'Aprovado Pelo Cliente';

      if (parseInt(value) === 5) return 'Cancelado';

      if (parseInt(value) === 6) return 'Enviado para entrega';

      if (parseInt(value) === 7) return 'Finalizado';
    },

    loadBudgets(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadBudgets', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', {
                  timeout: 2000
                });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 13)
        .then(r => {
          if (r.data.indexOf('budget-search') !== -1) {
            if (r.data.indexOf('budget-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('budget-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('budget-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('budget-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    destroy(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          if (parseInt(item.status) === 5) {
            toast.warning(
              `Não é possível excluir o orçamento N° ${item.code}  , o mesmo já está cancelado.`,
              {
                timeout: 2000
              }
            );
          } else if (parseInt(item.status) === 7) {
            toast.warning(
              `Não é possível excluir o orçamento N° ${item.code}  , o mesmo já está finalizado.`,
              {
                timeout: 2000
              }
            );
          } else {
            this.$store
              .dispatch('destroyBudget', item.id)
              .then(() => {
                this.loadBudgets(1);
                toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
              })
              .catch(r => {
                let error = r.response.data.errors[0].toString();
                toast.warning(`${error}`, { timeout: 2000 });
              });
          }
        }
      });
    },

    sectorOpen(id) {
      this.sector_modal = true;
      this.id = id;
    },

    submitStatus(status) {
      let params = {
        id: this.id,
        status: status
      };

      this.$store
        .dispatch('budgetUpdateStatus', params)
        .then(() => {
          this.showError = false;
          this.errors = [];
          this.loadBudgets(this.budget.current_page);
          this.sector_modal = false;
          if (parseInt(params.status) === 5) {
            toast.warning('Orçamento cancelado, não é possível alterar o status', {
              timeout: 1500
            });
          } else if (parseInt(params.status) === 7) {
            toast.warning('Orçamento finalizado, não é possível alterar o status', {
              timeout: 1500
            });
          } else {
            toast.success('Status atualizado com sucesso', { timeout: 1500 });
          }
        })
        .catch(e => {
          this.showError = true;
          this.errors = e.response.data.errors;
        });
    },

    deliveryCreate(id, client) {
      this.$router.push({
        name: 'budget-delivery-create',
        params: { id: id, client: client }
      });
    },

    deliveryEdit(r) {
      this.$router.push({
        name: 'budget-delivery-edit',
        params: { id: r.id, client: r.client_id, delivery: r.budget_delivery_id }
      });
    },

    loadUserMultiSelect(context) {
      return new Promise((resolve, reject) => {
        axios
            .get(`${URL_BASE}user-list`)
            .then(response => {
              context.commit('UPDATE_USER_LIST', response.data);
              return resolve(response.data);
            })
            .catch(errors => {
              return reject(errors);
            });
      });
    },


    initialDate() {
      let currentTime = new Date();

      let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = ("0" + day.getDate()).slice(-2);
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

  },

  computed: {
    selectStatusBudget() {
      return this.$store.state.var.selectStatusBudget;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectNameBudgetList() {
      return this.$store.state.var.selectNameBudgetList;
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },


    budget() {
      return this.$store.state.budget.items;
    },
    params() {
      return {
        page: this.budget.current_page,

        code: this.formData.code,
        name: this.formData.name,
        surname: this.formData.surname,
        name_budget_id: this.formData.name_budget_id,
        status: this.formData.status,
        date:this.formData.date,
        creator_user_id:this.formData.creator_user_id,
      };
    },

    me() {
      return this.$store.state.auth.me;
    }
  },

  unmounted() {
    window.removeEventListener('keyup',this.submit)
  }

};
</script>

<style src="@/assets/style/table.css"></style>

<style scoped>
.avatar__image {
  width: 30px;
}

.modals {
  z-index: 20;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.send-sector {
  width: 40%;
  min-height: 20%;
  overflow-y: auto;
  padding: 40px 20px;
  background: #ffffff;
  position: relative;
}

.send-sector .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.align-i {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.efc-disabled {
  color: var(--bs-btn-disabled-color);
  opacity: var(--bs-btn-disabled-opacity);
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
}

.modal-w {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}


</style>
