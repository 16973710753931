<template>
  <painel-component :itens="itensOne">
    <form v-if="showView">
      <row-component>
        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectBox" :searchable="true"
                       :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
        </row-col-component>


        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Vendedor:</label>
          <Multiselect
              v-model="formData.creator_user_id"
              :options="selectUserList"
              :searchable="true"
              :noOptionsText="noOptionsText"
              :noResultsText="noResultsText"
              track-by="value"
              label="name"

          >
            <template #option="{ option }">
              <div class="d-flex align-items-center">
                <img
                    v-if="option.icon"
                    :src="option.icon"
                    alt=""
                    class="rounded-circle me-2"
                    width="30"
                    height="30"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>

            <template #single-label="{ option }">
              <div class="d-flex align-items-center">
                <img
                    v-if="option.icon"
                    :src="option.icon"
                    alt=""
                    class="rounded-circle me-2"
                    width="25"
                    height="25"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>
          </Multiselect>

        </row-col-component>


        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Inicial:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.date_start" type-input="text"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Final:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.date_end" type-input="text"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Excel / PDF:</label>
          <Multiselect v-model="formData.excel_pdf" :options="selectTypeReport" :searchable="true"
                       :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" :items="buttonSubmit"/>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import RowColComponent from "@/components/row/RowColComponent.vue";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";

import {mask} from "vue-the-mask";
export default {
  name: "ReportBudget",
  components: {
    ButtonSubmit,
    InputComponent,
    Multiselect,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask
  },

  created() {
    this.$store.dispatch("updateNamePage", "Relatório PDV Caixa (Entrada)")
    this.checkACL()
    this.loadUserMultiSelect()
  },

  data() {
    return {
      itensOne: {
        title: "Gerar",
        icon: "ti-file-download"
      },

      buttonSubmit: {
        icon: 'ti-download',
        typeButton: "button"
      },

      formData: {
        status: 1,
        creator_user_id: '',
        date_start: this.initialDate(),
        date_end: this.dateCurrent(),
        excel_pdf: 2
      },

      showView: false
    }
  },

  methods: {
    submit() {
      const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://system.benepiscinas.com' : 'http://bene.d7mmarketing.com.br';
      const RESOURCE = "/private/report/box-total-entrance/WGtdsi61uQ77hwVMO26J"
      const params = new URLSearchParams({
        status: this.formData.status,
        creator_user_id: this.formData.creator_user_id,
        date_start: this.formData.date_start,
        date_end: this.formData.date_end,
        excel_pdf: this.formData.excel_pdf
      });

      const keysToRemove = ['status', 'date_start', 'date_end', 'excel_pdf'];
      keysToRemove.forEach(key => {
        if (this.formData[key] === null || this.formData[key] === "") {
          params.delete(key);
        }
      });

      const FULL_URL = `${URL_BASE}${RESOURCE}?${params.toString()}`
      window.open(FULL_URL)
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 22)
          .then((r) => {
            if (r.data.indexOf('report') !== -1) {
              this.showView = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    initialDate() {
      let currentTime = new Date();

      let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = ("0" + day.getDate()).slice(-2);
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

    dateCurrent() {
      let currentTime = new Date();

      let day = currentTime.getDate();
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = (day < 10 ? '0' : '') + day;
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

    loadUserMultiSelect() {
      this.$store.dispatch('loaderUserList')

    },


  },

  computed: {
    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    selectBox() {
      return this.$store.state.var.selectBox
    },

    selectTypeReport() {
      return this.$store.state.var.selectTypeReport
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },
  }
}
</script>