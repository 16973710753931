<template>
  <painel-component :itens="itensOne">

    <div role="button" class="btn-close" @click="closeCamera" v-if="showCodeBar"></div>


    <StreamBarcodeReader
        torch
        no-front-cameras
        @decode="onCamera"
        @loaded="onLoaded"
        v-if="showCodeBar"
    />

    <br>

    <error-component :errors="errors" v-if="showError" />
    <div class="clearfix" v-if="buttonAddRoute">
      <button-route class="float-end" :items="buttonRouteAdd" />
      <button-submit
        @click="printPDF"
        class="float-end mx-1"
        :items="{ icon: 'ti ti-printer' }"
      />
    </div>

    <form v-if="showView">
      <div class="aux my-4">
        <div class="avatar-form">
          <img
            v-if="formData.product_photo"
            :src="formData.product_photo"
            class="avatar__image"
            alt=""
          />
          <img v-else src="@/assets/images/avatar.png" class="avatar__image" alt="" />
        </div>
        <p>{{ formData.name }}</p>
        <router-link :to="{ name: 'product-edit-photo', id: this.id }" class="figure">
          <i class="ti ti-camera"></i>
        </router-link>
      </div>

      <row-component>

        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.status}">
          <label>Código de barras:</label>
          <div class="input-group">
            <input  v-model="formData.code_bar" type="text" class="form-control"/>
            <span  class="btnDefault input-group-text" @click="openCamera">
              <i class="ti ti-camera"></i>
            </span>
          </div>

        </row-col-component>


        <row-col-component
          :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.name }"
        >
          <label>Nome:</label>
          <input-component :type-input="'text'" v-model="formData.name" />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.status }"
        >
          <label>Status:</label>
          <Multiselect
            v-model="formData.status"
            :options="selectActiveInative"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.price }"
        >
          <label>Preço:</label>
          <input-component
            v-money="money"
            :type-input="'text'"
            v-model="formData.price"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.initial_inventory }"
        >
          <label>Estoque Inicial:</label>
          <input-component :type-input="'number'" v-model="formData.initial_inventory" />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.minimum_stock }"
        >
          <label>Estoque Mínimo:</label>
          <input-component :type-input="'number'" v-model="formData.minimum_stock" />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.brand_id }"
        >
          <label>Marca:</label>
          <Multiselect
            v-model="formData.brand_id"
            :options="selectBrandList"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.category_id }"
        >
          <label>Categoria:</label>
          <Multiselect
            v-model="formData.category_id"
            :options="selectCategoryList"
            :searchable="true"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.obs }"
        >
          <label>Observações:</label>
          <input-text-area :rows="4" v-model="formData.obs" />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
        >
          <button-submit
            :disaabled="sendForm"
            @submit="submit"
            :items="buttonSubmit"
            style="width: 100%"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
        >
          <button-route :items="buttonRoute" style="width: 100%" />
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from '@/components/alerts/ErrorComponent.vue';
import { useToast } from 'vue-toastification';
import { MONEY } from '@/configs/constants';
import { VMoney } from 'v-money';
import {StreamBarcodeReader} from "@teckel/vue-barcode-reader";

const toast = useToast();
export default {
  name: 'ProductUpdate',

  mounted() {
    window.addEventListener('keydown', this.OnkeyDown);
  },


  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Produtos');
    this.$store.dispatch('loadCategoryMultiSelect');
    this.$store.dispatch('loadBrandMultiSelect');
    this.checkACL();
    this.loadProduct();
  },

  components: {
    StreamBarcodeReader,
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    InputTextArea
  },

  directives: {
    money: VMoney
  },

  data() {
    return {
      itensOne: {
        title: 'Editar',
        icon: 'ti-edit'
      },

      buttonSubmit: {
        icon: 'ti-device-floppy',
        nameButton: 'Salvar',
        typeButton: 'button'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/product/search',
        name: 'Voltar'
      },

      buttonRouteAdd: {
        icon: 'ti-plus',
        route: '/product/create'
      },

      buttonAddRoute: false,

      formData: {
        name: '',
        status: '',
        price: '',
        initial_inventory: '',
        minimum_stock: '',
        brand_id: '',
        category_id: '',
        obs: '',
        code_bar: '',
        barcodeReader:''
      },

      money: MONEY,

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
      showCodeBar :false,
      audioSrc: require('@/assets/audio/beep.mp3'), // Caminho do arquivo local



    };
  },

  methods: {
    loadProduct() {
      this.$store
        .dispatch('loadProduct', this.id)
        .then(r => {
          this.formData = r;
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateProduct', this.formData)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            toast.success('Cadastro atualizado com sucesso', { timeout: 2000 });
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.scrollToTop();
            toast.error('Seu formulário contém erros verificar!', { timeout: 2000 });
          });
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    printPDF() {
      window.open(this.formData.printTAG, '_blank');
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 9)
        .then(r => {
          if (r.data.indexOf('product-edit') !== -1) {
            this.showView = true;

            if (r.data.indexOf('product-create') !== -1) {
              this.buttonAddRoute = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    openCamera(){
      this.showCodeBar = true;
    },

    playAudio(){
      let audio = new Audio(this.audioSrc);
      audio.addEventListener('canplaythrough', function() {
        audio.play();
      });
    },

    closeCamera(){
      this.showCodeBar = false;
    },

    onCamera(code) {
      this.playAudio()
      this.formData.code_bar = code
      this.showCodeBar = false;
      setTimeout(()=>{
        this.submit()
      },1000)


    },


    onErrorLog(error) {

      console.log(error)

    },

    onLoaded(){
    },


    OnkeyDown(e) {
      if (e.key == 'Enter') {
        if (this.formData.barcodeReader) {
          this.formData.code_bar=''
          this.setCodeBar(this.formData.barcodeReader);
        }
        this.setTimeoutCodeBar();
        return;
      }

      if (e.key != 'Shift') this.formData.barcodeReader += e.key;

      this.setTimeoutCodeBar();
    },

    setTimeoutCodeBar() {
      setTimeout(() => {
        this.formData.barcodeReader = '';
        // this.formData.code_bar = '';
      }, 1000);
    },

    setCodeBar(code) {
      this.formData.code_bar = code.replace('undefined','');
      this.submit()
    },



  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectCategoryList() {
      return this.$store.state.var.selectCategoryList;
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList;
    }
  },

  unmounted() {
    window.removeEventListener('keydown',this.OnkeyDown)
  }
};
</script>
