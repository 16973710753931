<template>
  <painel-component :itens="itensOne">
    <div class="tabs effect-3" v-if="showView">
      <input checked type="radio" id="tab-1" name="tab-effect-3" />
      <span>
        <i class="ti ti-info-square-rounded"></i>
        <p>Informações</p>
      </span>

      <input @click="eventClick" type="radio" id="tab-2" name="tab-effect-3" />
      <span>
        <i class="ti ti ti-brand-line"></i>
        <p>Observações</p>
      </span>

      <input type="radio" id="tab-3" name="tab-effect-3" />
      <span>
        <i class="i ti ti-calendar"></i>
        <p>Data</p>
      </span>

      <div class="line ease"></div>

      <div class="tab-content">
        <section id="tab-item-1">
          <form class="mt-5">
            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              >
                <label>Cliente:</label>
                <div class="input-group">
                  <input
                    disabled
                    type="text"
                    v-model="formData.client_name"
                    class="form-control"
                  />
                </div>
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              >
                <label>Telefone:</label>
                <div class="input-group">
                  <input
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="formData.phone"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              >
                <label>Serviço:</label>
                <Multiselect
                  v-model="formData.service_id"
                  :options="selectServiceList"
                  :searchable="true"
                  :noOptionsText="noOptionsText"
                  :noResultsText="noResultsText"
                  disabled
                />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
              >
                <label>Técnico Responsável:</label>
                <Multiselect
                  v-model="formData.technical_id"
                  :options="selectUserList"
                  :searchable="true"
                  :noOptionsText="noOptionsText"
                  :noResultsText="noResultsText"
                  track-by="value"
                  label="name"
                  disabled
                >
                  <template #option="{ option }">
                    <div class="d-flex align-items-center">
                      <img
                        v-if="option.icon"
                        :src="option.icon"
                        alt=""
                        class="rounded-circle me-2"
                        width="30"
                        height="30"
                      />
                      <span>{{ option.name }}</span>
                    </div>
                  </template>

                  <template #single-label="{ option }">
                    <div class="d-flex align-items-center">
                      <img
                        v-if="option.icon"
                        :src="option.icon"
                        alt=""
                        class="rounded-circle me-2"
                        width="25"
                        height="25"
                      />
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                </Multiselect>
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="line-division my-3">
            <h1 class="h1-text">
              <i class="calendar alternate icon"></i>
              Observações do cliente
            </h1>

            <div class="obsScroll">
              <div
                class="card p-3 bg-light my-3 overflow-auto"
                v-for="(obs, index) in formData.obsClient"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <img
                      v-if="obs.client_photo"
                      class="avatar__image mx-1"
                      :src="obs.client_photo"
                      alt=""
                    />
                    <img
                      v-else
                      class="avatar__image mx-1"
                      src="@/assets/images/avatar.png"
                      alt=""
                    />
                    {{ obs.client_name }}
                  </div>

                  <div>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-calendar-due"></i> {{ obs.date_create }}
                    </p>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-clock-filled"></i> {{ obs.hour_create }}
                    </p>
                  </div>
                </div>

                <div>
                  <p>{{ obs.obs }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="line-division my-3 mt-5">
            <h1 class="h1-text">
              <i class="calendar alternate icon"></i>
              Observações do técnico responsável
            </h1>

            <div class="obsScroll">
              <div
                class="card p-3 bg-light my-3 overflow-auto"
                v-for="(obs, index) in formData.obsTechnical"
                :key="index"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <img
                      v-if="obs.user_photo"
                      class="avatar__image mx-1"
                      :src="obs.user_photo"
                      alt=""
                    />
                    <img
                      v-else
                      class="avatar__image mx-1"
                      src="@/assets/images/avatar.png"
                      alt=""
                    />
                    {{ obs.user_name }}
                  </div>

                  <div>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-calendar-due"></i> {{ obs.date_create }}
                    </p>
                    <p class="d-inline-block mx-3">
                      <i class="ti ti-clock-filled"></i> {{ obs.hour_create }}
                    </p>
                  </div>
                </div>

                <div>
                  <p>{{ obs.obs }}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tab-item-3">
          <div>
            <div class="line-division my-3">
              <h1 class="h1-text">
                <i class="calendar alternate icon"></i>
                Datas
              </h1>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width: 25%">Nome</th>
                    <th scope="col" style="width: 25%">Tipo</th>
                    <th scope="col" style="width: 25%">Data</th>
                    <th scope="col" style="width: 25%">Hora</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(dateHour, index) in formData.datesHours" :key="index">
                    <td data-label="Nome">
                      <img
                        v-if="dateHour.user_photo"
                        class="avatar__image mx-1"
                        :src="dateHour.user_photo"
                        alt=""
                      />
                      <img
                        v-else
                        class="avatar__image mx-1"
                        src="@/assets/images/avatar.png"
                        alt=""
                      />
                      {{ dateHour.user_name }}
                    </td>
                    <td data-label="Tipo">{{ dateHourTypeString(dateHour.type) }}</td>
                    <td data-label="Tipo">{{ dateHour.date }}</td>
                    <td data-label="Tipo">{{ dateHour.hour }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>

    <row-component>
      <row-col-component
        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        <button-route class="w-100" :items="btnRoute" />
      </row-col-component>
    </row-component>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import { useToast } from 'vue-toastification';
import { mask } from 'vue-the-mask';

const toast = useToast();

export default {
  directives: {
    mask
  },
  props: {
    id: {
      required: true
    }
  },

  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    Multiselect,
    ButtonRoute
  },

  created() {
    this.$store.dispatch('loadServiceMultiSelect');
    this.$store.dispatch('loadUserMultiSelect');
    this.loadBudget();
    this.checkACL();
  },

  data() {
    return {
      itensOne: {
        title: 'Ver',
        icon: 'ti-pencil-plus'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonSearch: {
        icon: 'ti-search',
        typeButton: 'button'
      },

      buttonConfirm: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Confirmar'
      },

      btnSubmit: {
        icon: 'ti-device-floppy',
        nameButton: 'Salvar',
        typeButton: 'button'
      },

      btnSubmitHour: {
        icon: 'ti-circle-plusy',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      btnRoute: {
        icon: 'ti-arrow-back-up',
        route: '/service-order/search',
        name: 'Voltar'
      },

      buttonAddData: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonObsClient: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      modalClient: false,
      modalPhone: false,
      modal_add_date_hour: false,
      edit_date_hour: false,
      showView: false,
      modalObsClient: false,
      modalAddObsTechnical: false,

      formData: {
        id: '',
        name: '',
        phone: '',
        service_id: '',
        technical_id: '',
        obs_client: '',
        obs_technical: '',
        obsClient: [],
        obsTechnical: []
      },

      obsClient: [],
      obsTechnical: [],

      formHour: {
        id: this.id,
        type: '',
        date: this.getCurrentDate(), // Formato YYYY-MM-DD
        hour: this.getCurrentHour() // Formato HH:mm
      },

      addObsClient: {
        id: '',
        obs_client: ''
      },

      client: {
        type: '2',
        name: '',
        cpf: '',
        cnpj: ''
      }
    };
  },

  methods: {
    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadClientsBudgetMaintenanceList', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data.length === 0) {
                toast.info('Nada foi encontrado com os parâmetros informados!', {
                  timeout: 2000
                });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    loadBudget() {
      this.$store
        .dispatch('loadServiceOrder', this.id)
        .then(r => {
          this.formData = {
            ...r,
            obs_client: r.obsClient.length > 0 ? r.obsClient[0].obs : '',
            obs_technical: r.obsTechnical.length > 0 ? r.obsTechnical[0].obs : ''
          };
        })
        .catch(() => {
          toast.error('Erro desconhecido', { timeout: 2000 });
        });
    },

    selectClient(data) {
      this.modalClient = false;
      this.formData.id = data.id;
      this.formData.name = data.surname;
      this.formData.cnpj = data.cnpj;
      this.formData.cpf = data.cpf;
    },

    getCurrentDate() {
      const now = new Date();
      return now.toISOString().split('T')[0]; // Retorna YYYY-MM-DD
    },

    getCurrentHour() {
      const now = new Date();
      return now.toTimeString().slice(0, 5); // Retorna HH:mm
    },

    dateHourTypeString(value) {
      if (parseInt(value) === 1) return 'Visita';

      if (parseInt(value) === 2) return 'Serviço';
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 24)
        .then(r => {
          if (r.data.indexOf('service-order-edit') !== -1) {
            this.showView = true;

            if (r.data.indexOf('service-order-create') !== -1) {
              this.buttonRouteAdd = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    object() {
      return this.$store.state.var.selectClientBudgetMaintenanceList;
    },

    selectClientPhoneList() {
      return this.$store.state.var.selectClientPhoneList;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectServiceList() {
      return this.$store.state.var.selectServiceList;
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },

    selectTypeDate() {
      return this.$store.state.var.selectTypeDate;
    },

    me() {
      return this.$store.state.auth.me;
    },

    params() {
      return {
        name: this.client.name,
        type: this.client.type,
        cpf: this.client.cpf,
        cnpj: this.client.cnpj
      };
    }
  }
};
</script>

<style scoped>
.avatar__image__div {
  width: 10%;
}

.buttonSubmit {
  background-color: #36c1bd;
  color: white;
  border-color: #36c1bd;
}

.modal-search-budget-template .btn-close,
.modal-search-client .btn-close,
.modal-search-email .btn-close,
.modal-search-phone .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.avatar__image {
  width: 25px;
}

.modal-search-client {
  z-index: 6;
  width: 80%;
  padding: 2%;
  height: 60%;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 5px 10px 12px 0 #a6a6a6;
}

.modal-search-budget-template,
.modal-search-email,
.modal-search-phone {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.line-division {
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

.h1-text {
  color: white;
  padding: 10px;
  margin-top: 1px;
  font-size: 16px;
  width: fit-content;
  background-color: #36c1bd;
}

.modals {
  z-index: 1024;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.send-sector {
  width: 35%;
  min-height: 30%;
  overflow-y: auto;
  padding: 40px 20px;
  background: #ffffff;
  position: relative;
}

.modal-client {
  width: 65%;
  min-height: 00%;
  overflow-y: auto;
  padding: 40px 20px 0 20px;
  background: #ffffff;
  position: relative;
}

.send-sector .btn-close,
.modal-client .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
  width: calc(100% / 3);
}

.color-main {
  color: #36c1bd;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 33.3%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
  left: 66.6%;
}

.obsScroll {
  max-height: 250px; /* Altura máxima */
  overflow-y: auto; /* Scroll vertical */
}

@media (max-width: 991px) {
  .modal-search-client,
  .modal-search-budget-template,
  .modal-search-email,
  .modal-search-phone {
    width: 95%;
    height: 80%;
  }

  .send-sector {
    width: 95%;
    min-height: 30%;
  }
}
</style>
