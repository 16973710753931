import AdminComponent from '@/components/dashboard/AdminComponent';
import ServiceOrderSearch from '@/views/search/ServiceOrderSearch.vue';
import ServiceOrderCreate from '@/views/create/ServiceOrderCreate.vue';
import ServiceOrderUpdate from '@/views/update/ServiceOrderUpdate.vue';
import ServiceOrderShow from '@/views/show/ServiceOrderShow.vue';

export default {
  path: '/service-order',
  component: AdminComponent,
  meta: { auth: true },
  children: [
    { path: 'search', name: 'service-order-search', component: ServiceOrderSearch },
    { path: 'create', name: 'service-order-create', component: ServiceOrderCreate },
    { path: 'edit/:id', name: 'service-order-edit', component: ServiceOrderUpdate, props: true },
    { path: 'show/:id', name: 'service-order-show', component: ServiceOrderShow, props: true }
  ]
};

// BudgetMaintenanceShow
