export default {
  UPDATE_NAME_PAGE(state, name) {
    state.namePage = name;
  },

  UPDATE_USER_LIST(state, data) {
    state.selectUserList = data;
  },

  UPDATE_GROUP_USER_LIST(state, name) {
    state.selectGroupUserList = name;
  },

  UPDATE_CATEGORY_LIST(state, name) {
    state.selectCategoryList = name;
  },

  UPDATE_PRODUCT_LIST(state, name) {
    state.selectProductList = name;
  },

  UPDATE_PRODUCT_BRAND_LIST(state, name) {
    state.selectProductBrandList = name;
  },

  UPDATE_PRODUCT_PRICE_LIST(state, name) {
    state.selectProductPriceList = name;
  },

  UPDATE_FLEET_LIST(state, name) {
    state.selectFleetList = name;
  },

  UPDATE_BRAND_LIST(state, name) {
    state.selectBrandList = name;
  },

  UPDATE_SERVICE_LIST(state, name) {
    state.selectServiceList = name;
  },

  UPDATE_SERVICE_PRICE_LIST(state, name) {
    state.selectServicePriceList = name;
  },

  UPDATE_CLIENT_LIST(state, name) {
    state.selectClientList = name;
  },

  UPDATE_CLIENT_EMAIL_LIST(state, name) {
    state.selectClientEmailList = name;
  },

  UPDATE_CLIENT_PHONE_LIST(state, name) {
    state.selectClientPhoneList = name;
  },

  UPDATE_BUDGET_TEMPLATE_LIST(state, name) {
    state.selectBudgetTemplateList = name;
  },

  UPDATE_BUDGET_TEMPLATE_GET_LIST(state, name) {
    state.selectBudgetTemplateGetList = name;
  },

  UPDATE_ACCOUNT_BANK_LIST(state, name) {
    state.selectAccountBankList = name;
  },

  UPDATE_ACCOUNT_TYPE_LIST(state, name) {
    state.selectAccountList = name;
  },

  UPDATE_ACCOUNT_TYPE_SUB_LIST(state, name) {
    state.selectAccountSubList = name;
  },

  UPDATE_PROVIDER_LIST(state, name) {
    state.selectProviderList = name;
  },

  UPDATE_EMPLOYEE_LIST(state, name) {
    state.selectEmployeeList = name;
  },

  UPDATE_EMPLOYEE_BANK_LIST(state, name) {
    state.selectEmployeeBankList = name;
  },

  UPDATE_BUDGET_LIST_PAYMENT(state, name) {
    state.selectBudgetPaymentList = name;
  },

  UPDATE_CLIENT_BUDGET(state, name) {
    state.selectClientBudgetList = name;
  },

  UPDATE_CLIENT_BUDGET_MAINTENANCE(state, name) {
    state.selectClientBudgetMaintenanceList = name;
  },

  UPDATE_CATEGORY_BRAND_LIST(state, name) {
    state.selectCategoryBrandList = name;
  },

  UPDATE_GUEST_SCHEDULE_LIST(state, name) {
    state.selectGuestScheduleList = name;
  },

  UPDATE_NAME_BUDGET_LIST(state, name) {
    state.selectNameBudgetList = name;
  }
};
