export default {
    icon: 'ti ti-file-analytics',
    name: 'Relatório',
    subLinks: [


        {
            name: 'Conta bancária',
            route: '/report/account-bank',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'Cliente',
            route: '/report/client',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'Cliente E-mail',
            route: '/report/client-email',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'Cliente Telefone',
            route: '/report/client-phone',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'Financeiro Entrada (Previsão)',
            route: '/report/financial-entrance-preview',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'Financeiro Entrada (Efetivado)',
            route: '/report/financial-entrance-effective',
            pagePermissions: [
                'report'
            ],
        },



        {
            name: 'Financeiro Saída (Previsão)',
            route: '/report/financial-exit-preview',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'Financeiro Saída (Efetivado)',
            route: '/report/financial-exit-effective',
            pagePermissions: [
                'report'
            ],
        },






        {
            name: 'Produto',
            route: '/report/product',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'Produto Foto',
            route: '/report/product-photo',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'Produto (Entrda/Saída)',
            route: '/report/product-entrance-and-exit',
            pagePermissions: [
                'report'
            ],
        },






        {
            name: 'Orçamento',
            route: '/report/budget',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'PDV Caixa (Entrada)',
            route: '/report/box-total-entrance',
            pagePermissions: [
                'report'
            ],
        },

        {
            name: 'PDV Caixa (Saída)',
            route: '/report/box-total-exit',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'PDV Caixa (Movimentação)',
            route: '/report/box-total-movement',
            pagePermissions: [
                'report'
            ],
        },


        {
            name: 'PDV Vendas',
            route: '/report/sale',
            pagePermissions: [
                'report'
            ],
        },

    ],

    pageLinksNavCheck: [
        'report'
    ],
}
