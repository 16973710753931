<template>
  <painel-component :itens="itensOne">

    <div role="button" class="btn-close" @click="closeCamera" v-if="showCodeBar"></div>
    <StreamBarcodeReader
        torch
        no-front-cameras
        @decode="onCamera"
        @loaded="onLoaded"
        v-if="showCodeBar"
    />

    <br>

    <row-component>
      <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <label>Código de barras:</label>
        <div class="input-group">
          <input   type="text" class="form-control" v-model="formData.code_bar" />
          <span  class="btnDefault input-group-text" @click="openCamera" >
              <i class="ti ti-camera"></i>
            </span>
        </div>

      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <label>Código:</label>
        <input-component :type-input="'text'" v-model="formData.code" />
      </row-col-component>


      <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <label>Nome:</label>
        <input-component :type-input="'text'" v-model="formData.name" />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>Categoria:</label>
        <Multiselect
          v-model="formData.category_id"
          :options="selectCategoryList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>



      <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <label>Status:</label>
        <Multiselect
          v-model="formData.status"
          :options="selectActiveInative"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
        <label>Marca:</label>
        <Multiselect
          v-model="formData.brand_id"
          :options="selectBrandList"
          :searchable="true"
          :noOptionsText="noOptionsText"
          :noResultsText="noResultsText"
        />
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 paddingTop25'">
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2" />
        <button-route v-if="buttonCheck.create" :items="buttonRoute" />
      </row-col-component>
    </row-component>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" style="width: 15%">Código de Barra  </th>
          <th scope="col" style="width: 5%">Código </th>
          <th scope="col" style="width: 20%">Nome</th>
          <th scope="col" style="width: 5%">Quantidade</th>
          <th scope="col" style="width: 10%">Marca</th>
          <th scope="col" style="width: 10%">Valor</th>
          <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product, index) in this.object" :key="index">
          <td data-label="Código de barra">{{product.code_bar}}</td>
          <td data-label="Código">{{product.code}}</td>
          <td data-label="Nome">
            <img v-if="product.photo" class="avatar__image mx-1" :src="product.photo" alt="" />
            <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="" />
            {{ product.name }}
          </td>
          <td data-label="Quantidade">{{ product.initial_inventory }}</td>
          <td data-label="Marca">{{ product.name_brand }}</td>
          <td data-label="Valor">R$ {{ product.price }}</td>
          <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
            <button-edit v-if="buttonCheck.edit" :id-param="product.id" :route="'product-edit'" />
            <button-delete v-if="buttonCheck.delete" @click="destroy(product)" />
            <button-show v-if="buttonCheck.show" :id-param="product.id" :route="'product-show'" />
          </td>
        </tr>
      </tbody>
    </table>

    <button-pagination :pagination="product.itens" :offset="3" @paginate="loadProducts" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import { useToast } from 'vue-toastification';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import swal from 'sweetalert';
import {StreamBarcodeReader} from "@teckel/vue-barcode-reader";

const toast = useToast();
export default {
  name: 'ProductSearch',

  mounted() {
    window.addEventListener('keydown', this.OnkeyDown);
  },



  created() {
    this.$store.dispatch('updateNamePage', 'Produtos');
    this.$store.dispatch('loadCategoryMultiSelect');
    this.$store.dispatch('loadBrandMultiSelect');
    this.checkACL();
    this.loadProducts();

  },

  components: {
    StreamBarcodeReader,
    ButtonDelete,
    ButtonPagination,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
    ButtonShow
  },

  data() {
    return {
      itensOne: {
        title: 'Pesquisa',
        icon: 'ti-list'
      },

      buttonSubmit: {
        icon: 'ti-search'
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/product/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false,
        delete: false
      },

      object: {},

      formData: {
        code_bar:'',
        name: '',
        code: '',
        status: '',
        category_id: '',
        brand_id: '',
        barcodeReader:''
      },

      showCodeBar :false,
      audioSrc: require('@/assets/audio/beep.mp3'), // Caminho do arquivo local
      code_bar: '',


    };
  },

  methods: {
    submit() {
      this.loadProducts(1);
      this.searchSubmit = true;
    },

    loadProducts(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadProducts', { ...this.params, page })
          .then(r => {
            this.object = r.itens.data;
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.itens.data == '') {
                toast.info('Nada foi encontrado com os parâmetros informados!', { timeout: 2000 });
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 9)
        .then(r => {
          if (r.data.indexOf('product-search') !== -1) {
            if (r.data.indexOf('product-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('product-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('product-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('product-delete') !== -1) {
              this.buttonCheck.delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    destroy(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true
          },
          confirm: 'Sim'
        }
      }).then(result => {
        if (result) {
          this.$store
            .dispatch('destroyProduct', item.id)
            .then(() => {
              this.loadProducts(1);
              toast.success('Cadastro deletado com sucesso', { timeout: 2000 });
            })
            .catch(r => {
              let error = r.response.data.errors[0].toString();
              toast.warning(`${error}`, { timeout: 2000 });
            });
        }
      });
    },

    openCamera(){
      this.showCodeBar = true;
    },

    playAudio(){
      let audio = new Audio(this.audioSrc);
      audio.addEventListener('canplaythrough', function() {
        audio.play();
      });
    },

    closeCamera(){
      this.showCodeBar = false;
    },

    onCamera(code) {
      this.playAudio()
      this.formData.code_bar = code
      this.showCodeBar = false;
      setTimeout(()=>{
        this.submit()
      },1000)


    },




    OnkeyDown(e) {
      if (e.key == 'Enter') {
        if (this.formData.barcodeReader) {
          this.formData.code_bar=''
          this.setCodeBar(this.formData.barcodeReader);
        }
        this.setTimeoutCodeBar();
        return;
      }

      if (e.key != 'Shift') this.formData.barcodeReader += e.key;

      this.setTimeoutCodeBar();
    },

    setTimeoutCodeBar() {
      setTimeout(() => {
        this.formData.barcodeReader = '';
       // this.formData.code_bar = '';
      }, 1000);
    },

    setCodeBar(code) {
      this.formData.code_bar = code.replace('undefined','');
      this.loadProducts();
    },







  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative;
    },

    noResultsText() {
      return this.$store.state.var.noResultsText;
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText;
    },

    selectCategoryList() {
      return this.$store.state.var.selectCategoryList;
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList;
    },

    product() {
      return this.$store.state.product.items;
    },

    params() {
      return {
        page: this.product.current_page,
        name: this.formData.name,
        code: this.formData.code,
        status: this.formData.status,
        category_id: this.formData.category_id,
        brand_id: this.formData.brand_id,
        code_bar:this.formData.code_bar
      };
    },

    me() {
      return this.$store.state.auth.me;
    }
  },

   unmounted() {
    window.removeEventListener('keydown',this.OnkeyDown)
   }
};
</script>

<style src="@/assets/style/table.css"></style>

<style scoped>
.avatar__image {
  width: 25px;
}
</style>
