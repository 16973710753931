import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'service-order';
export default {
  loadOrderService(context, params) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_SERVICE_ORDER', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  storeServiceOrder(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  loadServiceOrder(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  updateServiceOrder(context, formData) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  destroyServiceOrder(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },


  storeOrderServiceAddDateHour(context, formHour) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}service-order-add-date-hour`, formHour)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  destroyDateHour(context, id) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}service-order-delete-date-hour/${id}`)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  updateServiceOrderDateHour(context, formHour) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}service-order-update-date-hour/${formHour.id}`, formHour)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('LOADER', false));
    });
  },

  //

  createObsClient(context, data) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}service-order-add-obs-client`, data)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  },

  createObsTechnical(context, data) {
    context.commit('LOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}service-order-add-obs-technical`, data)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('LOADER', false);
        });
    });
  }
};
