<template>
  <painel-component :itens="itensOne">
    <form v-if="showView">
      <row-component>
        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>


        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Tipo:</label>
          <Multiselect v-model="formData.type" :options="selectEntranceExitList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Inicial:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.date_start" type-input="text"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Data Final:</label>
          <input-component v-mask="'##/##/####'" v-model="formData.date_end" type-input="text"/>
        </row-col-component>


<!--

        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Marca:</label>
          <Multiselect v-model="formData.brand_id" :options="selectBrandList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Categoria:</label>
          <Multiselect v-model="formData.category_id" :options="selectCategoryList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>
-->

        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Excel / PDF:</label>
          <Multiselect v-model="formData.excel_pdf" :options="selectTypeReport" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" :items="buttonSubmit"/>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import RowColComponent from "@/components/row/RowColComponent.vue";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import InputComponent from "@/components/form/InputComponent.vue";
import {mask} from "vue-the-mask";

export default {
  name: "ReportProduct",
  components: {
    InputComponent,
    ButtonSubmit,
    Multiselect,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask
  },

  created() {
    this.$store.dispatch("updateNamePage", "Relatório Produto (Entrada/Sáida)")
    this.$store.dispatch('loadBrandMultiSelect')
    this.$store.dispatch('loadCategoryMultiSelect')
    this.checkACL()
  },

  data() {
    return {
      itensOne: {
        title: "Gerar",
        icon: "ti-file-download"
      },

      buttonSubmit: {
        icon: 'ti-download',
        typeButton: "button"
      },

      formData: {
        status: 1,
        type:"",
        date_start: this.initialDate(),
        date_end: this.dateCurrent(),
        excel_pdf: 2
      },

      showView: false
    }
  },

  methods: {
    submit() {
      const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://system.benepiscinas.com' : 'http://bene.d7mmarketing.com.br';
      const RESOURCE = "/private/report/product-entrance-and-exit/eaxDHruE89jZKaAt2NQ5"
      const params = new URLSearchParams({
        status: this.formData.status,
        type: this.formData.type,

        date_start: this.formData.date_start,
        date_end: this.formData.date_end,
        excel_pdf: this.formData.excel_pdf
      });

      const keysToRemove = ['status', 'brand_id', 'category_id', 'excel_pdf'];
      keysToRemove.forEach(key => {
        if (this.formData[key] === null || this.formData[key] === "") {
          params.delete(key);
        }
      });

      const FULL_URL = `${URL_BASE}${RESOURCE}?${params.toString()}`
      window.open(FULL_URL)
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 22)
          .then((r) => {
            if (r.data.indexOf('report') !== -1) {
              this.showView = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    initialDate() {
      let currentTime = new Date();

      let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = ("0" + day.getDate()).slice(-2);
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

    dateCurrent() {
      let currentTime = new Date();

      let day = currentTime.getDate();
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = (day < 10 ? '0' : '') + day;
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },


  },

  computed: {
    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    selectEntranceExitList() {
      return this.$store.state.var.selectEntranceExitList
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList
    },

    selectCategoryList() {
      return this.$store.state.var.selectCategoryList
    },

    selectTypeReport() {
      return this.$store.state.var.selectTypeReport
    },
  }
}
</script>