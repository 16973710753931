<template>
    <painel-component :itens="itensOne">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Nome:</label>
                <input-component :type-input="'text'" v-model="formData.name"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Entrada / Saída:</label>
                <Multiselect v-model="formData.type" :options="selectEntranceExitList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
                <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 5%">Código</th>
                <th scope="col" style="width: 20%">Criado por:</th>
                <th scope="col" style="width: 20%">Produto</th>
                <th scope="col" style="width: 10%">Entrada / Saída</th>
                <th scope="col" style="width: 10%">Quantidade</th>
                <th scope="col" style="width: 10%">Valor UN</th>
                <th scope="col" style="width: 15%">Valor Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(inventory, index) in this.object" :key="index">
                <td data-label="Código">{{ inventory.code }}</td>
                <td data-label="Criado por:">
                    <img v-if="inventory.photo_create" class="avatar__image mx-1" :src="inventory.photo_create" alt="">
                    <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                    {{ inventory.creator_user_name }}
                </td>
                <td data-label="Produto">{{ inventory.product_name }}</td>
                <td data-label="Entrada / Saída">{{ statusString(inventory.type) }}</td>
                <td data-label="Quantidade">{{ inventory.amount }}</td>
                <td data-label="Valor UN">R$ {{ inventory.price }}</td>
                <td data-label="Valor Total">R$ {{ inventory.price_total }}</td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="inventory.itens" :offset="3" @paginate="loadInventories"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()

export default {
    name: 'InventorySearch',

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada / Saída')
        this.$store.dispatch('loadProductMultiSelect')
        this.checkACL()
        this.loadInventories(1)
    },

    components: {
        ButtonPagination,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/inventory/create'
            },

            buttonCheck: {
                create: false,
            },

            object: {},

            formData: {
                name: '',
                type: ''
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadInventories(1)
            this.searchSubmit = true
        },

        statusString(value) {
            if (value === '1')
                return 'Entrada'
            return 'Saída'
        },

        loadInventories(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadInventories', {...this.params, page})
                    .then((r) => {
                        this.object = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data == '') {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 10)
                .then((r) => {
                    if (r.data.indexOf('inventorie-search') !== -1) {

                        if (r.data.indexOf('inventorie-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectEntranceExitList() {
            return this.$store.state.var.selectEntranceExitList
        },

        inventory() {
            return this.$store.state.inventory.items
        },

        params() {
            return {
                page: this.inventory.current_page,
                name: this.formData.name,
                type: this.formData.type
            }
        },

        me() {
            return this.$store.state.auth.me
        },

    }

}
</script>

<style src="@/assets/style/table.css">

</style>

<style scoped>
.avatar__image {
    width: 25px;
}
</style>
