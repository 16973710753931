<template>
  <painel-component :itens="itensOne">
    <form v-if="showView">
      <row-component>

        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Pessoa:</label>
          <Multiselect v-model="formData.type" :options="selectPessoa" :searchable="true"
                       :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
        </row-col-component>

        <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
        </row-col-component>




        <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
          <label>Excel / PDF:</label>
          <Multiselect v-model="formData.excel_pdf" :options="selectTypeReport" :searchable="true"
                       :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <button-submit @click="submit" :items="buttonSubmit"/>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import RowColComponent from "@/components/row/RowColComponent.vue";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";

import {mask} from "vue-the-mask";


export default {
  name: "ReportBudget",
  components: {
    ButtonSubmit,
    Multiselect,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask
  },

  created() {
    this.$store.dispatch("updateNamePage", "Relatório cliente e-mail")
    this.checkACL()
  },

  data() {
    return {
      itensOne: {
        title: "Gerar",
        icon: "ti-file-download"
      },

      buttonSubmit: {
        icon: 'ti-download',
        typeButton: "button"
      },

      formData: {
        status: 1,
        type:'',
        excel_pdf: 2
      },

      showView: false
    }
  },

  methods: {
    submit() {
      const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://system.benepiscinas.com' : 'http://bene.d7mmarketing.com.br';
      const RESOURCE = "/private/report/client-email/5cSHvDPB8MCL3yeujVdO"
      const params = new URLSearchParams({
        type: this.formData.type,
        status: this.formData.status,
        excel_pdf: this.formData.excel_pdf
      });

      const keysToRemove = ['status', 'date_start', 'date_end', 'excel_pdf'];
      keysToRemove.forEach(key => {
        if (this.formData[key] === null || this.formData[key] === "") {
          params.delete(key);
        }
      });

      const FULL_URL = `${URL_BASE}${RESOURCE}?${params.toString()}`
      window.open(FULL_URL)
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 22)
          .then((r) => {
            if (r.data.indexOf('report') !== -1) {
              this.showView = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    initialDate() {
      let currentTime = new Date();

      let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = ("0" + day.getDate()).slice(-2);
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },

    dateCurrent() {
      let currentTime = new Date();

      let day = currentTime.getDate();
      let month = currentTime.getMonth() + 1;
      let year = currentTime.getFullYear();

      day = (day < 10 ? '0' : '') + day;
      month = (month < 10 ? '0' : '') + month;

      return `${day}/${month}/${year}`
    },




  },

  computed: {
    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    selectPessoa() {
      return this.$store.state.var.selectPessoa
    },


    selectTypeReport() {
      return this.$store.state.var.selectTypeReport
    },

    selectUserList() {
      return this.$store.state.var.selectUserList;
    },
  }
}
</script>