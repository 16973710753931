import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import './assets/style/main.css'
import './assets/icons/tabler-icons.css'

import {NAME_TOKEN} from "@/configs/constants";

const tokenAcess = localStorage.getItem(NAME_TOKEN)
if (tokenAcess)
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAcess}`;

if (process.env.NODE_ENV === 'production') {
    // axios.defaults.baseURL = 'https://www.benepiscinas.com.br';
    axios.defaults.baseURL = 'https://system.benepiscinas.com/';
} else {
    axios.defaults.baseURL = 'https://bene.d7mmarketing.com.br';
}
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App)
    .use(Toast)
    .use(store)
    .use(router)
    .mount('#app')

store.dispatch('loginCheck')
    .catch(() => {
        store.dispatch('logout')
    })
